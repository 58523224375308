import React, { useContext } from 'react';
import { SiteContext } from '../context/ContextProvider';
import { Link } from 'react-router-dom';

const Transition = () => {
    const {loading, transition} = useContext(SiteContext);
    
    const totalPayable = transition?.reduce((total, item) => total + (parseFloat(item?.payable) || 0), 0);

    function formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
    
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString(undefined, options);
      const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
    
      return `${formattedDate} - ${formattedTime}`;
    }



    return !loading ? (
        <>
            <div className='row'>
                <h4 className='text-center py-4'>My all transitions</h4>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col">Trn ID</th>
                    <th scope="col">Data</th>
                    <th scope="col">Type</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    transition?.map((item, i) => (
                        <tr key={i}>
                            <td>{item?.trx_id}</td>
                            <td>{formatDate(item?.created_at)}</td>
                            <td>{item?.type}</td>
                            <td>৳{item?.payable}</td>
                        <td>
                          {
                            item.from === "plan" ? <Link to={`/order-details/${item.id}/${item.from}`} className='btn btn-success btn-sm'>View order</Link> : <Link to={`/order-details/${item.id}`} className='btn btn-success btn-sm'>View order</Link>
                          }
                                
                            </td>
                        </tr>
                        )
                    )
                }
                <tr className='bg-dark text-light'>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>৳{totalPayable}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </>
     ) : (
      <div
        className=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
          background: "#fff",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
};

export default Transition;