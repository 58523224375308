import axios from "axios";
import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import countryList from "react-select-country-list";
import Swal from "sweetalert2";
import { SiteContext } from "../context/ContextProvider";

function MyProfile() {
  const { isUser, setLoading, loading, baseUrl } = useContext(SiteContext);
  const countryData = useMemo(() => countryList().getData(), []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("postal_code", data.postal_code);
      formData.append("date_of_birth", data.date_of_birth);
      formData.append("gender", data.gender);
      formData.append("country", data.country);
      formData.append("profile_photo", data.profile_photo[0]);

      const responseData = await axios.post(
        `${baseUrl}/api/profile`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("_token")
            )}`,
          },
        }
      );

      setLoading(false);
      if (responseData?.status === 200) {
        Swal.fire("", `${responseData?.data?.message}`, "success");
        localStorage.setItem("name", JSON.stringify(data.name));
        localStorage.setItem("email", JSON.stringify(data.email));
        setLoading(false);
        window.location.reload();
      } else if (responseData?.status === 204) {
        Swal.fire({
          icon: "error",
          title: "Alert",
          text: `${responseData?.data?.message}`,
        });
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return !loading ? (
    <>
      <div className="shop-bx shop-profile">
        <div className="shop-bx-title clearfix">
          <h5 className="text-uppercase">Profile Information</h5>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="mt-3"
        >
          <div className="row m-b30">
            {/* <Select options={options} value={value} onChange={changeHandler} /> */}

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor="formcontrolinput" className="form-label">
                  Name:
                </label>
                <input
                  {...register("name")}
                  defaultValue={isUser?.user?.name}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor={`formcontrolinput`} className="form-label">
                  Email :
                </label>
                <input
                  {...register("email")}
                  defaultValue={isUser?.user?.email}
                  type="text"
                  className="form-control"
                  readOnly
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor={`formcontrolinput`} className="form-label">
                  Country :
                </label>
                <select
                  {...register("country")}
                  className="form-control"
                  defaultValue={isUser?.profile?.country || ""}
                >
                  <option value="">-Select a country-</option>
                  {countryData.map((item, ind) => (
                    <option key={ind} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {/* <input {...register("country")} defaultValue={isUser?.profile?.country} type="text" className="form-control"/> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor={`formcontrolinput`} className="form-label">
                  Postal code :
                </label>
                <input
                  {...register("postal_code")}
                  defaultValue={isUser?.profile?.postal_code}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor={`formcontrolinput`} className="form-label">
                  Dath of birth :
                </label>
                {isUser?.profile?.date_of_birth ? (
                  <input
                    {...register("date_of_birth")}
                    defaultValue={
                      isUser?.profile?.date_of_birth.split("-")[0] +
                      "-" +
                      isUser?.profile?.date_of_birth.split("-")[1] +
                      "-" +
                      isUser?.profile?.date_of_birth.split("-")[2]
                    }
                    type="date"
                    className="form-control"
                  />
                ) : (
                  <input
                    name="date_of_birth"
                    defaultValue={""}
                    type="date"
                    className="form-control"
                  />
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor={`formcontrolinput`} className="form-label">
                  Profile photo :
                </label>
                <input
                  className="form-control"
                  {...register("profile_photo")}
                  type="file"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlFor={`formcontrolinput`} className="form-label">
                  Gender:
                </label>
                <select
                  {...register("gender")}
                  className="form-control"
                  defaultValue={isUser?.profile?.gender || ""}
                >
                  <option value="">-Select a gender-</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary btnhover mt-2">
            Save changes
          </button>
        </form>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default MyProfile;
