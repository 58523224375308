import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PdfAnnotator } from "react-pdf-highlighter";
import { useNavigate, useSearchParams } from "react-router-dom";
import ChapterDropdown from "../components/PdfFileViewer/ChapterDropdown";
import SettingsDropdown from "../components/PdfFileViewer/SettingsDropdown";
import "./viewer.scss";
import { SiteContext } from "../context/ContextProvider";

export const API_URL = "https://admin.readersfm.com";

export const api = axios.create({
  baseURL: API_URL + "/api",
  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
  },
});

export const useBookIdQuery = () => {
  const [searchParams] = useSearchParams();
  const book_id = searchParams.get("book_id");
  const chapter_id = searchParams.get("chapter_id");
  return { book_id, chapter_id };
};

function ViewPdfFile() {
  const { baseUrl } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const { book_id, chapter_id } = useBookIdQuery();
  const [pdf_url, setPdfUrl] = useState("");
  const [shareText, setShareText] = useState("");
  const [bookDetails, setBookDetails] = useState(null);
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    if (book_id) {
      api.get("/book-by-chapter/" + book_id + "?type=ebook").then((v) => {
        const data = v.data?.data;
        const isChapter = data?.chapters && data?.chapters?.length > 0;
        setBookDetails(data);
        setHighlights([]);
        if (isChapter && chapter_id) {
          const findChapters = data?.chapters?.find((v) => v.id == chapter_id);

          if (!findChapters) {
            navigate(`/book/pdf/show?book_id=${book_id}`);
          } else {
            const pdf_url = findChapters?.pdf_url;
            setPdfUrl(pdf_url);

            api
              .get(
                `/chapter-highlight-note?book_id=${book_id}&chapter_id=${chapter_id}`
              )
              .then((v) => {
                const webText =
                  v.data?.data?.chapter_highlight_or_note?.[0]?.web_text;
                if (webText) {
                  try {
                    let data = JSON.parse(webText);
                    setHighlights(data);
                  } catch (e) {}
                }
                setLoading(false);
              });
          }
        } else {
          const pdfUrl = data?.book?.pdf_url;
          setPdfUrl(pdfUrl);
          api
            .get("/highlight-note?book_id=" + book_id)
            .then((v) => {
              const webText = v.data?.data?.highlight_or_note?.[0]?.web_text;

              if (webText) {
                try {
                  let data = JSON.parse(webText);
                  setHighlights(data);
                } catch (e) {}
              }
              setLoading(false);
            })
            .catch(() => {});
        }
      });
    } else {
      navigate(-1);
    }
  }, [book_id, chapter_id]);

  // const [bookDetails] = useState(() =>
  //   JSON.parse(localStorage.getItem("book_details") || "{}")
  // );

  const updateHighlights = (h) => {
    if (chapter_id) {
      api
        .post("/chapter-save-highlight-note", {
          book_id,
          chapter_id,
          web_text: JSON.stringify(h || highlights),
        })
        .catch(() => {});
    } else {
      api
        .post("/save-highlight-note", {
          book_id,
          web_text: JSON.stringify(h || highlights),
        })
        .catch(() => {});
    }
  };

  const handleNewHighlight = (highlight) => {
    let newH = [...highlights, highlight];
    setHighlights(newH);
    updateHighlights(newH);
  };

  const [setting, setSetting] = useState({
    scrollMode: "vertical",
    pageMode: "single",
    zoom: "auto",
  });

  const deleteHighlight = (id) => {
    const filter = highlights.filter((v) => v.id !== id);
    setHighlights(filter);
    updateHighlights(filter);
  };

  const updateHighlight = (highlightId, comment) => {
    let newHighlights = highlights.map((v) => {
      if (v.id === highlightId) {
        v.comment = comment;
      }
      return v;
    });

    setHighlights(newHighlights);
    updateHighlights(newHighlights);
  };

  const isChapter = bookDetails?.chapters && bookDetails?.chapters?.length > 0;

  const activeChapter = useMemo(() => {
    if (isChapter) {
      return bookDetails?.chapters?.find((v) => v.id == chapter_id);
    } else {
      return {};
    }
  }, [chapter_id, bookDetails, isChapter]);

  const handleChapterSelect = (v) => {
    navigate(`/book/pdf/show?book_id=${book_id}&chapter_id=${v.id}`);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  if (loading) return null;

  return (
    <div className="view-audio-book-container">
      <div className="v_header-wrapper">
        <div className="container  v_header v_header-v2 d-flex justify-content-between">
          <div className="d-flex" style={{ gap: "30px" }}>
            <div onClick={() => navigate(-1)} className="">
              <svg
                width={32}
                height={32}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9999 11H6.41394L11.7069 5.70703L10.2929 4.29303L2.58594 12L10.2929 19.707L11.7069 18.293L6.41394 13H20.9999V11Z"
                  fill="black"
                />
              </svg>
            </div>
            {isChapter && (
              <ChapterDropdown
                chapters={bookDetails?.chapters}
                activeChapter={chapter_id}
                onChapterSelect={handleChapterSelect}
              />
            )}
          </div>
          <div>
            {bookDetails?.book?.name || ""}{" "}
            {activeChapter ? `- ${activeChapter?.name}` : ""}
          </div>

          <SettingsDropdown settingState={[setting, setSetting]} />
        </div>
      </div>

      <div className="  v_container-padding-v2  v_audio-player-container position-relative">
        <PdfAnnotator
          onNewHightlight={handleNewHighlight}
          // pdfSrc={`${API_URL}/get-pdf?url=${pdf_url}`}
          // pdfSrc={pdf_url}
          pdfSrc={`${baseUrl}/get-pdf?url=${pdf_url}`}
          highlights={highlights}
          onDeleteHighlight={deleteHighlight}
          onUpdateHighlight={updateHighlight}
          isDualPageArrow={setting.scrollMode === "horizontal"}
          pdfScaleValue={setting.zoom}
          onShareQuote={(text) => {
            setShareText(text);
          }}
        />
        {shareText && (
          <div className="position-absolute top-50 start-50 translate-middle shadow p-4 share-modal">
            <div
              onContextMenu={handleContextMenu}
              onCopy={handleCopy}
              style={{ pointerEvents: "none" }}
            >
              {shareText}
            </div>
            <div className="mt-3">
              <button
                onClick={() => {
                  const predefinedShareText =
                    "**Your compelling share message here!**"; // Replace with your desired text
                  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )}&quote=${encodeURIComponent(predefinedShareText)}`;
                  window.open(shareUrl, "_blank");
                }}
                type="button"
                class="btn btn-primary"
              >
                Facebook
              </button>
              <button type="button" class="btn btn-primary ms-2">
                Twitter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewPdfFile;
