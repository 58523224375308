import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//Components
import { SiteContext } from "../context/ContextProvider";
const Verification = () => {
  useEffect(() => {
    document.title = "ReadersFM | Registration";
  }, []);
  const { loading, setLoading, baseUrl } = useContext(SiteContext);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { email, message, name, password } = state;

  const handleVerification = (event) => {
    event.preventDefault();
    const form = event.target;
    const otp = form.otp.value;
    const email_phone = email;
    setLoading(true);
    const user = { email_phone, otp };

    setError("");

    fetch(`${baseUrl}/api/signup-verify`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.code === 422) {
          setError(data?.message);
          setLoading(false);
          return;
        }

        if (data?.code === 200) {
          const token = data?.data?.token;
          localStorage.setItem("name", JSON.stringify(data?.data?.user?.name));
          localStorage.setItem(
            "email",
            JSON.stringify(data?.data?.user?.email)
          );
          localStorage.setItem("_token", JSON.stringify(token));
          setLoading(false);
          navigate("/my-dashboard");
          window.location.reload();
        }
      })
      .catch((error) => setLoading(false));
  };

  const resendHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    const user = { name, email, password, password_confirmation: password };
    setError("");
    fetch(`${baseUrl}/api/register`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.code === 302) {
          setError(data?.message);
          setLoading(false);
          return;
        }

        if (data?.code === 200) {
          navigate("/verification", {
            state: {
              email: `${data?.data?.email_phone}`,
              name: `${name}`,
              password: `${password}`,
              message: `${data?.message}`,
            },
          });
          setLoading(false);
        }

        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  return !loading ? (
    <div>
      <div className="page-content">
        {/* <PageTitle parentPage="Shop" childPage="Registration" /> */}
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <form onSubmit={handleVerification}>
                    <h4 className="text-secondary">Verification</h4>
                    <p className="font-weight-600">{email}</p>
                    <p className="font-weight-600">{message}</p>
                    <p style={{ color: "red" }}>{error}</p>

                    <div className="mb-4">
                      <label className="label-title">OTP *</label>
                      <input
                        name="otp"
                        required=""
                        className="form-control"
                        placeholder="Enter your OTP"
                        type="number"
                      />
                    </div>

                    <div className="text-left">
                      <button
                        type="submit"
                        className="btn btn-primary btnhover w-100 me-2"
                      >
                        Register
                      </button>
                    </div>
                  </form>

                  <div className="mt-4">
                    <p>
                      <Link onClick={resendHandler} className="pe-auto">
                        Resend code
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default Verification;
