import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../context/ContextProvider";

function ReturnRefundPolicy() {
  const [htmlContent, setHtmlContent] = useState("");
  const { baseUrl } = useContext(SiteContext);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/page?type=${"return-refund-policy"}`
        );
        setHtmlContent(response.data.data.page.page_body);
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    fetchPageData();
  }, [baseUrl]);

  useEffect(() => {
    document.title = "ReadersFM | Return Refund Policy";
  }, []);

  return (
    <div className="page-content">
      <section className="content-inner-1 shop-account">
        <div className="container">
          <div className="row">
            {/* <!-- Left part start --> */}
            <div className="col-lg-8 col-md-7 col-sm-12 inner-text">
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12 m-b30 mt-md-0 mt-4">
              <aside className="side-bar sticky-top right">
                <div className="service_menu_nav widget style-1">
                  <ul className="menu">
                    <li className="menu-item">
                      <Link to={"/about-us"}>About Us</Link>
                    </li>
                    <li className="menu-item">
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                    <li className="menu-item">
                      <Link to={"/terms-and-conditions"}>
                        Terms & Conditions
                      </Link>
                    </li>
                    <li className="menu-item active">
                      <Link to={"/return-refund-policy"}>
                        Return Refund Policy
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ReturnRefundPolicy;
