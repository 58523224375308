import React, { useContext, useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import { SiteContext } from "../context/ContextProvider";

function heartToggle() {
  var heartBlaste = document.querySelector(".heart");
  if (heartBlaste) {
    heartBlaste.classList.toggle("heart-blast");
  }
}

const accordList = [
  { name: "Architecture" },
  { name: "Art" },
  { name: "Action" },
  { name: "Biography" },
  { name: "Body, Mind & Spirit" },
  { name: "Business & Economics" },
  { name: "Children Fiction" },
  { name: "Children Non-Fiction" },
  { name: "Comics & Graphics" },
  { name: "Cooking" },
  { name: "Crafts & Hobbies" },
  { name: "Design" },
  { name: "Drama" },
  { name: "Education" },
  { name: "Family & Relationships" },
  { name: "Fiction" },
  { name: "Foreign Language" },
  { name: "Games" },
  { name: "Gardening" },
  { name: "Health & Fitness" },
  { name: "History" },
  { name: "House & Home" },
  { name: "Humor" },
  { name: "Literary Collections" },
  { name: "Mathematics" },
  { name: "Medical" },
  { name: "Nature" },
  { name: "Performing Arts" },
  { name: "Pets" },
  { name: "Show others" },
];

function Footer({ footerChange, logoImage }) {
  const [footerData, setFooterData] = useState(null);
  const [categories, setCategories] = useState([]);
  const { baseUrl, loading, setLoading } = useContext(SiteContext);

  const footerLogoUrl = footerData?.cmsfooter?.logo.footer_logo;
  const appStoreLogoUrl = footerData?.cmsfooter?.logo.app_store_logo;
  const appStoreUrl = footerData?.cmsfooter?.logo.app_store_link;
  const playStoreLogoUrl = footerData?.cmsfooter?.logo.play_store_logo;
  const playStoreUrl = footerData?.cmsfooter?.logo.play_store_link;
  const footerTop = footerData?.cmsfooter?.footer_top;
  const footerMiddle = footerData?.cmsfooter?.footer_middle;

  const sectionOneData = footerMiddle?.filter((item) => item?.section == 1);
  const sectionTwoData = footerMiddle?.filter((item) => item?.section == 2);
  const sectionThreeData = footerMiddle?.filter((item) => item?.section == 3);
  const sectionFourData = footerMiddle?.filter((item) => item?.section == 4);
  const sectionFiveData = footerMiddle?.filter((item) => item?.section == 5);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/categories`);
        const data = await response.json();
        setCategories(data?.data?.categories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategories();
  }, [baseUrl, setLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/home-footer`);
        const data = await response.json();
        setFooterData(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl, setLoading]);

  //const [open, setOpen] = useState(false);
  const d = new Date();
  const [accordBtn, setAccordBtn] = useState();
  return !loading ? (
    <>
      <footer className={`site-footer ${footerChange}`}>
        <div className="footer-category">
          <div className="container">
            <div className="category-toggle">
              <Link
                to={"#"}
                className={`toggle-btn ${accordBtn ? "active" : ""}`}
                onClick={() => setAccordBtn(!accordBtn)}
              >
                Books categories
              </Link>
              <div className="toggle-items row">
                <Collapse in={accordBtn} className="footer-col-book">
                  <ul>
                    {categories?.map((category, ind) => (
                      <li key={ind}>
                        <Link to={`/category-books/${category?.id}`}>
                          {category?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-3 col-lg-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={"/"}>
                      <img src={footerLogoUrl} alt="" />
                    </Link>
                  </div>
                  <p className="text">{footerTop?.section_1}</p>
                  <div className="dz-social-icon style-1">
                    <ul>
                      {sectionOneData?.map((item) => (
                        <li key={item?.id}>
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noreferrer"
                            title={item?.name}
                          >
                            <img
                              src={item?.icon}
                              alt={item?.name}
                              style={{ height: "30px" }}
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="row mt-4 align-items-center">
                    <div className="col-md-6 col-6">
                      <a
                        href={playStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={playStoreLogoUrl} alt="" />
                      </a>
                    </div>
                    <div className="col-md-6 col-6">
                      <a
                        href={appStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={appStoreLogoUrl}
                          alt="app-store"
                          className="lg:mt-2 mt-lg-0"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="widget widget_services">
                  <h5 className="footer-title">{footerTop?.section_2}</h5>
                  <ul>
                    {sectionTwoData?.map((item, i) => (
                      <li key={i}>
                        <Link to={item?.link}>{item?.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-3 col-sm-4 col-4 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="widget widget_services">
                  <h5 className="footer-title">{footerTop?.section_3}</h5>
                  <ul>
                    {sectionThreeData?.map((item, i) => (
                      <li key={i}>
                        <Link to={item?.link}>{item?.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <div className="widget widget_services">
                  <h5 className="footer-title">{footerTop?.section_4}</h5>
                  <ul>
                    {sectionFourData?.map((item, i) => (
                      <li key={i}>
                        <Link to={item?.link}>{item?.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-12 col-sm-12 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="widget widget_getintuch">
                  <h5 className="footer-title">{footerTop?.section_5}</h5>
                  <ul>
                    {sectionFiveData?.map((item, i) => (
                      <li key={i}>
                        <img
                          src={item?.icon}
                          alt="icon"
                          style={{ height: "30px", marginLeft: "-50px" }}
                        />
                        <span className="ps-3">{item?.link}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row fb-inner">
              <div className="col-lg-4 col-md-12 text-start">
                <p className="copyright-text mt-3">
                  ReadersFM - © {d.getFullYear()} All Rights Reserved
                </p>
              </div>
              <div className="col-lg-8 col-md-12 ">
                <img className="w-full" src="/payment.png" alt="payment" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default Footer;
