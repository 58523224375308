import React, { useContext, useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import { SiteContext } from "../context/ContextProvider";
import CounterSection from "../elements/CounterSection";
import BookCard from "../layouts/BookCard";

const lableBlogData = [
  { name: "Architecture" },
  { name: "Art" },
  { name: "Action" },
  { name: "Biography & Autobiography" },
  { name: "Body, Mind & Spirit" },
  { name: "Business & Economics" },
  { name: "Children Fiction" },
  { name: "Children Non-Fiction" },
  { name: "Comics & Graphic Novels" },
  { name: "Cooking" },
  { name: "Crafts & Hobbies" },
  { name: "Design" },
  { name: "Drama" },
  { name: "Education" },
  { name: "Family & Relationships" },
  { name: "Fiction" },
  { name: "Foreign Language Study" },
  { name: "Games" },
  { name: "Gardening" },
  { name: "Health & Fitness" },
  { name: "History" },
  { name: "House & Home" },
  { name: "Humor" },
  { name: "Literary Collections" },
  { name: "Mathematics" },
];

function CategoryBooks() {
  useEffect(() => {
    document.title = "ReadersFM | Category Books";
  }, []);

  const [accordBtn, setAccordBtn] = useState();
  const [allCategoryBooks, setAllCategoryBooks] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const { baseUrl, setLoading, loading } = useContext(SiteContext);
  const [activeTab, setActiveTab] = useState("tab1");

  const { id } = useParams();

  // pagination start
  const [currentPage, setCurrentPage] = useState(
    `${baseUrl}/api/category/${id}`
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${currentPage}`);
        const data = await response.json();
        setAllCategoryBooks(data?.data?.books);
        setSubCategory(data?.data?.subcategory);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, setLoading]);

  const totalPages = allCategoryBooks?.last_page;

  const handlePageClick = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
        <Link
          to="#"
          style={
            allCategoryBooks?.current_page === i
              ? {
                  backgroundColor: "#274898",
                  color: "#fff",
                  pointerEvents: "none",
                }
              : null
          }
          className={`page-link`}
          onClick={() =>
            handlePageClick(
              `${baseUrl}/api/books?page=`+i
            )
          }
        >
          {i}
        </Link>
      </li>
    );
  }
  // pagination end

  const tabContent1 = (
    <>
      <div className="row book-grid-row">
        {allCategoryBooks?.data?.length === 0 && (
          <span className="text-danger">No data found!</span>
        )}
        {allCategoryBooks?.data?.map((book, i) => (
          <BookCard key={i} book={book} height={"350px"} />
        ))}
      </div>
      <div className="row page mt-0">
        <div className="col-md-6">
          <p className="page-text">
            Showing {allCategoryBooks?.to} from {allCategoryBooks?.total} data
          </p>
        </div>
        <div className="col-md-6">
          <nav aria-label="Blog Pagination">
            <ul className="pagination style-1 p-t20">
              {/* pagination start   */}
              <li className="page-item">
                {allCategoryBooks?.prev_page_url && (
                  <Link
                    to={"#"}
                    className="page-link prev"
                    onClick={() =>
                      handlePageClick(allCategoryBooks?.prev_page_url)
                    }
                  >
                    Prev
                  </Link>
                )}
              </li>

              {paginationItems}

              <li className="page-item">
                {allCategoryBooks?.next_page_url && (
                  <Link
                    to={"#"}
                    className="page-link next"
                    onClick={() =>
                      handlePageClick(allCategoryBooks?.next_page_url)
                    }
                  >
                    Next
                  </Link>
                )}
              </li>

              {/* pagination end   */}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );

  const tabContent2 = (
    <div>
      {subCategory?.map((data, index) => (
        <Link to={`/subcategory-books/${data?.id}`} key={index}>
          {data?.name}
        </Link>
      ))}
    </div>
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return !loading ? (
    <>
      {allCategoryBooks?.length !== 0 && allCategoryBooks ? (
        <div className="page-content">
          <section className="content-inner-1 border-bottom">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="title">Category Books</h4>
              </div>
              <div className="filter-area m-b30">
                <div className="grid-area">
                  <div className="shop-tab">
                    <ul
                      className="nav text-center product-filter justify-content-end"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={`nav-link ${
                                activeTab === "tab1" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("tab1")}
                            >
                              Books
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={`nav-link  ${
                                activeTab === "tab2" ? "active" : ""
                              }`}
                              style={{ width: 150 }}
                              onClick={() => handleTabClick("tab2")}
                            >
                              Sub Categories
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <Collapse in={accordBtn} className="acod-content">
                <div>
                  <div className="widget widget_services">
                    {lableBlogData.map((item, ind) => (
                      <div className="form-check search-content" key={ind}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`productCheckBox${ind + 1}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`productCheckBox${ind + 1}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
              <div>{activeTab === "tab1" ? tabContent1 : tabContent2}</div>
            </div>
          </section>
          <div className="bg-white py-5">
            <div className="container">
              <ClientsSlider />
            </div>
          </div>
          <section className="content-inner">
            <div className="container">
              <div className="row sp15">
                <CounterSection />
              </div>
            </div>
          </section>
          {/* <NewsLetter /> */}
        </div>
      ) : (
        <div className="text-center text-danger py-5">Data is empty !</div>
      )}
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default CategoryBooks;
