import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//Components
import { SiteContext } from "../context/ContextProvider";

function Login() {
  const { isUser, setUser, loading, setLoading, baseUrl } = useContext(SiteContext);
  const [forgotPass, setForgotPass] = useState();
  const [forgotEmail, setForgotEmail] = useState("");
  

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.target;
    const email = form.email.value;
    const password = form.password.value;

    const user = { email, password };

    // Assuming setError, setSuccess, navigate, and setUser are defined correctly.

    setError("");
    setSuccess("");

    // Uncomment the fetch URL when you're ready to use it
    fetch(`${baseUrl}/api/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.code === 200) {
          const name = data?.data?.user?.name;
          const userEmail = data?.data?.user?.email;
          const token = data?.data?.token;
          localStorage.setItem("_token", JSON.stringify(token));
          localStorage.setItem("name", JSON.stringify(name));
          localStorage.setItem("email", JSON.stringify(userEmail));
          navigate(from);
          window.location.reload();
          setUser(data?.data?.user);
          setLoading(false);
        } else {
          setError("Invalid email or password.");
          setLoading(false);
        }
      })
      .catch((error) => {
        setError("Invalid email or password.");
        setLoading(false);
      });
  };

  const forgotSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Fetch data from the server
    fetch(`${baseUrl}/api/forget-password`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({"emailorphone": forgotEmail}),
    })
    .then((res) => res.json())
      .then((data) => {
      if (data?.status === true) {
          navigate("/reset-password", { state: { message: data?.message, email_phone: data?.data?.email_phone } });
          setLoading(false);
      } else {
        setError(data?.message);
        setLoading(false);
      }
    })
    .catch((error) => {
      setError("Invalid email or password.");
      setLoading(false);
    });
  };


  useEffect(() => {
    document.title = "ReadersFM | Login";
    if (isUser) {
      navigate("/my-dashboard");
      return;
    }
  }, [isUser, navigate]);

  return !loading ? (
    <>
      <div className="page-content">
        {/* <PageTitle parentPage="Shop" childPage="Login" /> */}
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <div className="tab-content">
                    <h4>NEW READERS</h4>
                    <p>
                      The ReadersFM platform creates an account for you, you You
                      can read ebooks and listen to audiobooks. Be able to go
                      through the checkout process faster, save multiple
                      shipping addresses, view and track your orders in your
                      account, and more.
                    </p>
                    <Link
                      to={"/registration"}
                      className="btn btn-primary btnhover m-r5 button-lg radius-no"
                    >
                      CREATE AN ACCOUNT
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4">
                {location.state && (
                  <div className="alert alert-danger" role="alert">
                    You have to login first!
                  </div>
                )}
                <div className="login-area">
                  <div className="tab-content nav">
                    <form
                      onSubmit={handleLogin}
                      className={` col-12 ${forgotPass ? "d-none" : ""}`}
                    >
                      <h4 className="text-secondary">LOGIN</h4>
                      <p className="font-weight-600">
                        If you have an account with us, please log in.
                      </p>
                      <p style={{ color: "red" }}>{error}</p>
                      <p style={{ color: "green" }}>{success}</p>
                      <div className="mb-4">
                        <label className="label-title">E-MAIL *</label>
                        <input
                          name="email"
                          required=""
                          className="form-control"
                          placeholder="Your Email"
                          type="email"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="label-title">PASSWORD *</label>
                        <input
                          name="password"
                          required
                          className="form-control"
                          placeholder="Type Password"
                          type="password"
                        />

                      </div>
                      <div className="text-left">
                        <button
                          type="submit"
                          className="btn btn-primary btnhover me-2"
                        >
                          login
                        </button>
                        <Link
                          tp={"#"}
                          className="m-l5"
                          onClick={() => setForgotPass(!forgotPass)}
                        >
                          <i className="fas fa-unlock-alt"></i> Forgot Password
                        </Link>
                      </div>
                    </form>

                    <form
                      onSubmit={forgotSubmit}
                      className={`  col-12 ${forgotPass ? "" : "d-none"}`}
                    >
                      <h4 className="text-secondary">FORGET PASSWORD ?</h4>
                      <p className="font-weight-600">
                        We will send you an email to reset your password.{" "}
                      </p>
                      {/* {error && (
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      )} */}
                      <div className="mb-3">
                        <label className="label-title">E-MAIL *</label>
                        <input
                          onChange={(e) => setForgotEmail(e.target.value)}
                          required=""
                          className="form-control"
                          placeholder="Your Email Id"
                          type="email"
                        />
                      </div>
                      <div className="text-left">
                        <Link
                          to={"#"}
                          className="btn btn-outline-secondary btnhover m-r10 active"
                          onClick={() => setForgotPass(!forgotPass)}
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-primary btnhover"
                          
                        >
                          Submit
                        </button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default Login;
