import React, { useContext, useEffect } from "react";

//Components
import HomeMainSlider from "../components/Home/HomeMainSlider";
import BookSaleSlider from "./../components/Home/BookSaleSlider";
import ClientsSlider from "./../components/Home/ClientsSlider";
import FeaturedSlider from "./../components/Home/FeaturedSlider";
import OfferSlider from "./../components/Home/OfferSlider";
import RecomendedSlider from "./../components/Home/RecomendedSlider";

//element
import { SiteContext } from "../context/ContextProvider";
import CounterSection from "./../elements/CounterSection";
import PublisherSlider from "../components/Home/PublisherSlider";
import CategoryBookSlider from "../components/Home/CategoryBookSlider";

const iconBlog = [
  { title: "Quick Delivery", iconClass: "flaticon-power" },
  { title: "Secure Payment", iconClass: "flaticon-shield " },
  { title: "Best Quality", iconClass: "flaticon-like" },
  { title: "Return Guarantee", iconClass: "flaticon-star" },
];

function Index1() {
  useEffect(() => {
    document.title = "ReadersFM | Home";
  }, []);

  const { loading } = useContext(SiteContext);

  return !loading ? (
    <>
      <div className="page-content bg-white">
        <div className="main-slider style-1">
          <HomeMainSlider />
        </div>
        <div className="bg-white py-5 mt-3">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>

        <div className="bg-white py-5 mt-3">
          <div className="container">
            <PublisherSlider />
          </div>
        </div>

        <section className="">
          <div className="container">
            <RecomendedSlider />
          </div>
        </section>

        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              {iconBlog.map((data, ind) => (
                <div className="col-lg-3 col-sm-6 col-6" key={ind}>
                  <div className="icon-bx-wraper style-1 m-b30 text-center">
                    <div className="icon-bx-sm m-b10">
                      <i className={`icon-cell ${data.iconClass}`} />
                    </div>
                    <div className="icon-content">
                      <h5 className="dz-title m-b10">{data.title}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Trending book  */}
        <section className="content-inner-1">
          <div className="container">
            <BookSaleSlider />
          </div>
        </section>

        <FeaturedSlider />

        <section className="content-inner-2">
          <div className="container">
            <OfferSlider />
          </div>
        </section>

        {/* Category slider  */}
        <CategoryBookSlider />

        {/* <section className="content-inner-2 testimonial-wrapper">
          <TestimonialSlider />
        </section> */}
        <section className="content-inner">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        {/* <NewsLetter /> */}
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default Index1;
