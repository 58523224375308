import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "../../assets/css/carosoul/slider.css";

//Images

//import { EffectFade, Autoplay , Parallax, Pagination} from "swiper";

import SwiperCore, {
  Autoplay,
  EffectFade,
  FreeMode,
  Navigation,
  Pagination,
  Parallax,
  Thumbs,
} from "swiper";
import { SiteContext } from "../../context/ContextProvider";
SwiperCore.use([Parallax, Thumbs, FreeMode, Autoplay, Pagination, EffectFade]);

export default function HomeMainSlider() {
  const { baseUrl } = useContext(SiteContext);
  const [HomeMainSliderData, setHomeMainSliderData] = useState(null);
  const [featuredBooks, setFeaturedBooks] = useState(null);
  const [slideBooks, setSlideBooks] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/api/home-slider`)
      .then((response) => response.json())
      .then((data) => {
        setSlideBooks(data.data.cmssliders);
        setHomeMainSliderData(data?.data?.cmssliders);
        setFeaturedBooks(data?.data?.featured_books);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [baseUrl, setHomeMainSliderData]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const paginationRef = React.useRef(null);
  return (
    <>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {slideBooks.map((slide, index) => (
          <SwiperSlide key={index}>
            <img src={slide?.image} className="sliderHeight" alt="" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        ref={paginationRef}
        className="swiper-pagination-about about-pagination swiper-pagination-clickable swiper-pagination-bullets"
      ></div>

      <Swiper
        className="swiper-container main-swiper-thumb"
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={"auto"}
        //slidesPerView= {"auto"}
        //slidesPerView= {1}
        loop={true}
        speed={1500}
        //freeMode={true}
        effect={"fade"}
        watchSlidesProgress={true}
        autoplay={{
          delay: 2800,
        }}
        modules={[EffectFade, Autoplay, Pagination]}
      >
        {featuredBooks?.map((featuredBook, index) => (
          <SwiperSlide key={index}>
            <div className="books-card">
              <div className="dz-media">
                <Link to={`/books-detail/${featuredBook?.id}`}>
                  <img src={featuredBook?.book_image} alt="book" />
                </Link>
              </div>
              <div className="dz-content">
                <Link to={`/books-detail/${featuredBook?.id}`}>
                  <h5 className="title mb-0">{featuredBook?.book_name}</h5>
                </Link>
                <div className="dz-meta">
                  <ul>
                    <li>by Napoleon Hill</li>
                  </ul>
                </div>
                <div className="book-footer">
                  <div className="price">
                    <span className="price-num">
                      ${featuredBook?.book_price}
                    </span>
                  </div>
                  <div className="rate">
                    <i className="flaticon-star text-yellow"></i>
                    <i className="flaticon-star text-yellow"></i>
                    <i className="flaticon-star text-yellow"></i>
                    <i className="flaticon-star text-yellow"></i>
                    <i className="flaticon-star text-yellow"></i>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
