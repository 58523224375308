import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import CounterSection from "../elements/CounterSection";
import BookCard from "../layouts/BookCard";
import { SiteContext } from "../context/ContextProvider";

const lableBlogData = [
  { name: "Architecture" },
  { name: "Art" },
  { name: "Action" },
  { name: "Biography & Autobiography" },
  { name: "Body, Mind & Spirit" },
  { name: "Business & Economics" },
  { name: "Children Fiction" },
  { name: "Children Non-Fiction" },
  { name: "Comics & Graphic Novels" },
  { name: "Cooking" },
  { name: "Crafts & Hobbies" },
  { name: "Design" },
  { name: "Drama" },
  { name: "Education" },
  { name: "Family & Relationships" },
  { name: "Fiction" },
  { name: "Foreign Language Study" },
  { name: "Games" },
  { name: "Gardening" },
  { name: "Health & Fitness" },
  { name: "History" },
  { name: "House & Home" },
  { name: "Humor" },
  { name: "Literary Collections" },
  { name: "Mathematics" },
];

function AllBooks() {
  useEffect(() => {
    document.title = "ReadersFM | All Books";
  }, []);
  const { loading, setLoading, baseUrl } = useContext(SiteContext);
  const [allBooks, setAllBooks] = useState(null);

  // pagination start
  const [currentPage, setCurrentPage] = useState(`${baseUrl}/api/books?page=1`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${currentPage}`);
        const data = await response.json();
        setAllBooks(data?.data?.books);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, setLoading]);

  const totalPages = allBooks?.last_page;

  const handlePageClick = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
        <Link
          to="#"
          style={
            allBooks?.current_page === i
              ? {
                  backgroundColor: "#274898",
                  color: "#fff",
                  pointerEvents: "none",
                }
              : null
          }
          className={`page-link`}
          onClick={() => handlePageClick(`${baseUrl}/api/books?page=` + i)}
        >
          {i}
        </Link>
      </li>
    );
  }
  // pagination end

  return !loading ? (
    <>
      <div className="page-content">
        <section className="content-inner-1 border-bottom">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="title">All Books</h4>
            </div>
            <div className="filter-area m-b30">
              <div className="grid-area">
                <div className="shop-tab">
                  <ul
                    className="nav text-center product-filter justify-content-end"
                    role="tablist"
                  ></ul>
                </div>
              </div>
            </div>

            <div className="row book-grid-row">
              {allBooks?.data?.length === 0 && (
                <span className="text-danger">No data found!</span>
              )}
              {allBooks?.data.map((book, i) => (
                <BookCard key={i} book={book} height={"350px"} />
              ))}
            </div>

            <div className="row page mt-0">
              <div className="col-md-6">
                <p className="page-text">
                  Showing {allBooks?.to} from {allBooks?.total} data
                </p>
              </div>
              <div className="col-md-6">
                <nav aria-label="Blog Pagination">
                  <ul className="pagination style-1 p-t20">
                    {/* pagination start  */}
                    <li className="page-item">
                      {allBooks?.prev_page_url && (
                        <Link
                          to={"#"}
                          className="page-link prev"
                          onClick={() =>
                            handlePageClick(allBooks?.prev_page_url)
                          }
                        >
                          Prev
                        </Link>
                      )}
                    </li>

                    {paginationItems}

                    <li className="page-item">
                      {allBooks?.next_page_url && (
                        <Link
                          to={"#"}
                          className="page-link next"
                          onClick={() =>
                            handlePageClick(allBooks?.next_page_url)
                          }
                        >
                          Next
                        </Link>
                      )}
                    </li>
                    {/* pagination end  */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <section className="content-inner">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        {/* <NewsLetter /> */}
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default AllBooks;
