import React, { useContext, useState } from 'react';
import { SiteContext } from '../context/ContextProvider';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const { baseUrl, loading, setLoading } = useContext(SiteContext);
    const [OTP, setOTP] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [errorMsg, setError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const forgotSubmit = (e) => {
        e.preventDefault();
      fetch(`${baseUrl}/api/password-reset-verify`, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify({"otp":OTP,"emailorphone": location.state.email_phone, "password":newPassword}),
        })
        .then((res) => res.json())
        .then((data) => {
            if (data?.success) {
              console.log(data?.success);
              const name = data?.success?.data?.name;
              const email = data?.success?.data?.email;
              const token = data?.success?.data?.remember_token;
              localStorage.setItem("_token", JSON.stringify(token));
              localStorage.setItem("name", JSON.stringify(name));
              localStorage.setItem("email", JSON.stringify(email));
              navigate("/my-dashboard");
              setLoading(false);
            } else {
                setError(data?.message);
                setLoading(false);
            }
        })
            .catch((error) => {
            console.log(error);
        });
    };
    return !loading ? (
    <>
      <div className="page-content">
        {/* <PageTitle parentPage="Shop" childPage="Login" /> */}
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row">
                
              <div className="col-lg-6 col-md-6 mb-4 mx-auto">
                {location.state && (
                  <div className="alert alert-success" role="alert">
                    {location.state.message}
                  </div>
                )}
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}
                <div className="login-area">
                  <div className="tab-content nav">
                    

                    <form
                      onSubmit={forgotSubmit}
                      className={`  col-12 `}
                    >
                      <h4 className="text-secondary">Set new password</h4>
                      <div className="mb-3">
                        <label className="label-title">OTP *(from your email)</label>
                        <input
                          onChange={(e) => setOTP(e.target.value)}
                          required=""
                          className="form-control"
                          placeholder="Enter new password"
                          type="text"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="label-title">NEW PASSWORD *</label>
                        <input
                          onChange={(e) => setNewPassword(e.target.value)}
                          required=""
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                        />
                      </div>
                      <div className="text-left">
                        <Link
                          to={"/login"}
                          className="btn btn-outline-secondary btnhover m-r10 active"
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-primary btnhover"
                          
                        >
                          Submit
                        </button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default ResetPassword;