import React, { useContext } from "react";
import { SiteContext } from "../context/ContextProvider";
import { Button } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "./constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AccountDeleteModal from "../components/AccountDeleteModal";

const DeleteAccount = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { isUser, setUser } = useContext(SiteContext);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("_token"));
  const handleDeleteAccount = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/account-delete?public_key=${token}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        toast.success(response.data.message ?? "Account Delete Successfully");
        localStorage.removeItem("_token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        setUser(null);
        setModalShow(false);
        window.location.reload();
        navigate("/");
      }
    } catch (error) {}
  };
  return (
    <div>
      <h3>Profile Information</h3>
      <p>
        <strong>Name: </strong>
        {isUser?.user?.name}
      </p>
      <p>
        <strong>Email: </strong>
        {isUser?.user?.email}
      </p>
      <Button variant={`danger`} onClick={() => setModalShow(true)}>
        Delete Account
      </Button>
      <AccountDeleteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleAccountDelete={handleDeleteAccount}
      />
    </div>
  );
};

export default DeleteAccount;
