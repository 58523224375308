import React, { useContext } from 'react';
import { SiteContext } from '../context/ContextProvider';
import rswitch from 'rswitch';
import { useNavigate } from 'react-router-dom';

const MyBooks = () => {
  const { loading, setLoading, myBooks, isUser } = useContext(SiteContext);
  const navigate = useNavigate();
    
    const handleReadBook = (book) => {
        setLoading(true);
        if (!isUser) {
        setLoading(false);
        navigate("/login", { state: { from: window.location.pathname } });
        return;
        }
        const fileType = book?.book_file_type;
        const book_id = book?.id;
        rswitch(fileType, {
        pdf: () => {
            navigate(`/book/pdf/show?book_id=${book_id}`);
        },
        epub: () => {
            navigate(`/book/epub/show?book_id=${book_id}`);
        },
        });
    };
    
    const handleReadAudio = (book) => {
    if (!isUser) {
      setLoading(false);
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }
    const book_id = book?.id;
    navigate(`/book/audio/show?book_id=${book_id}`);
    };
  
    

    

    return !loading ? (
        <>
            <div className='row'>
                <h4 className='text-center py-4'>My all books</h4>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cover</th>
                    <th scope="col">Name</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    myBooks?.map((book, i) => (
                        <tr key={i}>
                            <th scope="row">{i+1}</th>
                            <td>
                                <img style={{"width":"80px"}} src={book?.book_details?.book_image} alt="Book Cover" />
                            </td>
                            <td>{book?.book_details?.book_name}</td>
                            <td>
                                <>
                                  {book?.book_details?.book_type === 3 && (
                                    <>
                                      <button
                                        onClick={handleReadAudio}
                                        type="submit"
                                        className="btn btn-primary btnhover btnhover2 ms-2"
                                      >
                                        <span>Listen Now</span>
                                      </button>
                                      <button
                                        onClick={handleReadBook}
                                        type="submit"
                                        className="btn btn-primary btnhover btnhover2 ms-2"
                                      >
                                        <span>Read Now</span>
                                      </button>
                                    </>
                                  )}
                                  {book?.book_details?.book_type === 2 && (
                                    <button
                                            onClick={()=>handleReadAudio(book?.book_details)}
                                      type="submit"
                                      className="btn btn-primary btnhover btnhover2 ms-2"
                                    >
                                      <span>Listen Now</span>
                                    </button>
                                  )}
                                  {book?.book_details?.book_type === 1 && (
                                    <button
                                      onClick={()=>handleReadBook(book?.book_details)}
                                      type="submit"
                                      className="btn btn-primary btnhover btnhover2 ms-2"
                                    >
                                      <span>Read Now</span>
                                    </button>
                                  )}
                                </>
                                {/* <button className='btn btn-success btn-sm' onClick={()=>handleReadBook(book?.book_details)}>Read book</button> */}
                            </td>
                        </tr>
                        )
                    )
                }
                    
                    
                </tbody>
            </table>
        </>
    ) : (
        <div
          className=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 999,
            background: "#fff",
            opacity: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
};

export default MyBooks;