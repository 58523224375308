import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

//Components
import { SiteContext } from "../context/ContextProvider";

function SavedBooks() {
  const { savedBooks, setLoading, loading, baseUrl } = useContext(SiteContext);

  const handleDeleteClick = (bookId) => {
    const id = bookId;
    setLoading(true);
    fetch(`${baseUrl}/api/new-delete-save-book/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        Swal.fire("", `${data?.message}`, "success");
        setLoading(false);
      })
      .catch((error) => {});
  };

  return !loading ? (
    <div className="page-content">
      <section className="content-inner-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                {savedBooks?.length !== 0 && savedBooks ? (
                  <table className="table check-tbl">
                    <tbody>
                      {savedBooks.map((data, index) => (
                        <tr key={index}>
                          <td className="product-item-img">
                            <img src={data.book_details.book_image} alt="" />
                          </td>
                          <td className="product-item-name">
                            {data.book_details.book_name}
                          </td>
                          <td className="product-item-totle">
                            <Link
                              to={`/books-detail/${data.book_details.id}`}
                              className="btn btn-primary btnhover"
                            >
                              View Details
                            </Link>
                          </td>
                          <td className="product-item-close">
                            <Link
                              to={"#"}
                              className="ti-close"
                              onClick={() =>
                                handleDeleteClick(data.savebook_id)
                              }
                            ></Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center text-danger">No saved book</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default SavedBooks;
