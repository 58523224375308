import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
//import "swiper/css";
import { Navigation, Pagination } from "swiper";
import BookCard from "../../layouts/BookCard";
import { SiteContext } from "../../context/ContextProvider";

function OfferSlider() {
  const { baseUrl } = useContext(SiteContext);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [offerBooks, setOfferBooks] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/home-special`
        );
        const data = await response.json();
        setOfferBooks(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl]);

  return (
    <>
      {offerBooks?.special_offer_books?.length !== 0 && (
        <>
          <div className="section-head book-align">
            <h2 className="title mb-0">{offerBooks?.name}</h2>
            <div className="pagination-align style-1">
              <div
                className="book-button-prev swiper-button-prev"
                ref={navigationPrevRef}
              >
                <i className="fa-solid fa-angle-left"></i>
              </div>
              <div
                className="book-button-next swiper-button-next"
                ref={navigationNextRef}
              >
                <i className="fa-solid fa-angle-right"></i>
              </div>
            </div>
          </div>
          <Swiper
            className="swiper-container  book-swiper"
            speed={1500}
            parallax={true}
            slidesPerView={5}
            spaceBetween={30}
            autoHeight={true}
            loop={true}
            pagination={{
              el: ".swiper-pagination-two",
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            modules={[Navigation, Pagination]}
            breakpoints={{
              1200: {
                slidesPerView: 5,
              },
              1191: {
                slidesPerView: 4,
              },
              767: {
                slidesPerView: 3,
              },
              591: {
                slidesPerView: 1,
                centeredSlides: true,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
                centeredSlides: true,
              },
            }}
          >
            {offerBooks?.special_offer_books?.map((item, ind) => (
              <SwiperSlide key={ind}>
                <BookCard book={item} height={"280px"} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </>
  );
}
export default OfferSlider;
