import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AudioBookImage from "../assets/images/Audiobook.svg";
import EBookBookImage from "../assets/images/E-Book.svg";
import chapterImage from "../assets/images/chapter-icon.png";
import { API_URL } from "./constant";

import axios from "axios";
//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import CounterSection from "../elements/CounterSection";

//Images
import { SiteContext } from "../context/ContextProvider";
import authorAvatar from "./../assets/images/author_avatar.png";

// import "../assets/scss/custom.scss";
//utils
import StarRatings from "react-star-ratings";
import rswitch from "rswitch";
import StarRating from "../components/rating";

function CommentBlog({ title, image, rating, review, date }) {
  return (
    <>
      <div className="comment-body" id="div-comment-3">
        <div className="d-flex">
          <div className="comment-author">
            <img src={image} alt="" className="avatar" />
          </div>
          <div className="comment-content">
            <h4>{title}</h4>
            <StarRating rating={rating} />
            <p>{review}</p>
          </div>
        </div>
        <p>{date}</p>
      </div>
    </>
  );
}

function BookDetail() {
  useEffect(() => {
    document.title = "ReadersFM | Book Details";
  }, []);
  const { baseUrl } = useContext(SiteContext);
  const [bookDetails, setBookDetails] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { isUser, addToCartHandler } = useContext(SiteContext);
  const { id } = useParams();

  const renditionRef = useRef(null);
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [selections, setSelections] = useState([]);

  // Get Day, Month and Year
  const dateString = bookDetails?.book?.created_at;
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const handleReadBook = () => {
    if (!isUser) {
      setLoading(false);
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }
    const fileType = bookDetails?.book?.book_file_type;
    const book_id = bookDetails?.book.id;
    rswitch(fileType, {
      pdf: () => {
        navigate(`/book/pdf/show?book_id=${book_id}`);
      },
      epub: () => {
        navigate(`/book/epub/show?book_id=${book_id}`);
      },
    });
  };
  const handleChapter = (chapter) => {
    if (!isUser) {
      setLoading(false);
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }
    chapter.file_type == "pdf"
      ? navigate(
          `/book/pdf/show?book_id=${bookDetails.book.id}&chapter_id=${chapter.id}`
        )
      : navigate(
          `/book/epub/show?book_id=${bookDetails.book.id}&chapter_id=${chapter.id}`
        );
    // navigate(`/book/pdf/show?book_id=${book_id}`);
  };
  const handleAudioChapter = (chapter) => {
    if (!isUser) {
      setLoading(false);
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }
    navigate(
      `/book/audio/show?book_id=${bookDetails.book.id}&chapter_id=${chapter.id}`
    );
  };
  const handleReadAudio = () => {
    if (!isUser) {
      setLoading(false);
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }
    const book_id = bookDetails?.book.id;
    navigate(`/book/audio/show?book_id=${book_id}`);
  };

  useEffect(() => {
    // Fetch Book Details
    const fetchBookDetails = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/book/${id}?user_id=${isUser?.user?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("_token")
              )}`,
            },
            mode: "no-cors",
          }
        );
        const data = response.data;
        setBookDetails(data?.data);
        setPdfUrl(data?.data?.book?.book_pdf_url);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBookDetails();
  }, [id, isUser?.user?.id, baseUrl]);

  const navigate = useNavigate();
  const handleSaveBook = () => {
    if (!isUser) {
      navigate("/login", { state: { from: window.location.pathname } });
    } else {
      const user_id = isUser?.user?.id;
      const book_id = bookDetails?.book?.id;

      const data = { user_id, book_id };

      fetch(`${baseUrl}/api/new-save-book`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.code === 200) {
            Swal.fire("", `${data?.message}`, "success");
          } else if (data?.code === 204) {
            Swal.fire({
              icon: "error",
              title: "Alert",
              text: `${data?.message}`,
            });
          }
        })
        .catch((error) => {});
    }
  };

  /* eslint-disable */

  useEffect(() => {
    if (renditionRef.current) {
      function setRenderSelection(cfiRange, contents) {
        setSelections(
          selections?.concat({
            text: renditionRef.current.getRange(cfiRange).toString(),
            cfiRange,
          })
        );

        localStorage.setItem(
          "highlights",
          JSON.stringify(
            selections?.concat({
              text: renditionRef.current.getRange(cfiRange).toString(),
              cfiRange,
            })
          )
        );

        renditionRef.current.annotations.add(
          "highlight",
          cfiRange,
          {},
          null,
          "hl",
          { fill: "red", "fill-opacity": "0.5", "mix-blend-mode": "multiply" }
        );
        contents.window.getSelection().removeAllRanges();
      }
      renditionRef.current.on("selected", setRenderSelection);
      return () => {
        renditionRef.current.off("selected", setRenderSelection);
      };
    }
  }, [setSelections, selections]);

  const userName = JSON.parse(localStorage.getItem("name"));
  const userEmail = JSON.parse(localStorage.getItem("email"));
  const userToken = JSON.parse(localStorage.getItem("_token"));

  // saved book delete
  const handleDeleteClick = (bookId) => {
    const id = bookId;
    setLoading(true);
    fetch(`${API_URL}/new-delete-save-book/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        Swal.fire("", `${data?.message}`, "success");
        setLoading(false);
      })
      .catch((error) => {});
  };
  const handleReplay = (e) => {
    e.preventDefault();

    if (!userToken && !isUser) {
      navigate("/login");
      return;
    }
    axios
      .post(
        `${API_URL}/feedback/create
    `,
        { book_id: bookDetails?.book?.id, rating, message },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          title: "Good job!",
          text: res.data.message,
          icon: "success",
        });
        setRating(0);
        setMessage("");
      });
  };

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return !isLoading ? (
    <>
      <div className="page-content bg-grey">
        <section className="content-inner-1">
          <div className="container">
            <div className="row book-grid-row style-4 m-b60">
              <div className="col">
                <div className="dz-box">
                  <div className="dz-media">
                    <img src={bookDetails?.book?.book_image} alt="book" />
                    <div className="sticker">
                      {bookDetails?.book?.book_type == 2 && (
                        <img
                          className="sticker-image"
                          src={AudioBookImage}
                          alt="Audiobook"
                        />
                      )}

                      {bookDetails?.book?.book_type == 1 && (
                        <img
                          className="sticker-image"
                          src={EBookBookImage}
                          alt="Ebook"
                        />
                      )}

                      {bookDetails?.book?.book_type == 3 && (
                        <div>
                          <img
                            className="sticker-image"
                            src={AudioBookImage}
                            alt="Audiobook"
                          />
                          <img
                            className="sticker-image"
                            src={EBookBookImage}
                            alt="Ebook"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dz-content">
                    <div className="dz-header">
                      <h3 className="title">{bookDetails?.book?.book_name}</h3>
                      <div className="shop-item-rating">
                        <div className="d-lg-flex d-sm-inline-flex d-flex align-items-center">
                          <ul className="dz-rating">
                            <li>
                              <i className="flaticon-star text-yellow"></i>
                            </li>
                            <li>
                              <i className="flaticon-star text-yellow"></i>
                            </li>
                            <li>
                              <i className="flaticon-star text-yellow"></i>
                            </li>
                            <li>
                              <i className="flaticon-star text-yellow"></i>
                            </li>
                            <li>
                              <i className="flaticon-star text-muted"></i>
                            </li>
                          </ul>
                          <h6 className="m-b0">4.0</h6>
                        </div>
                        <div className="social-area">
                          <ul className="dz-social-icon style-3">
                            <li className="me-2">
                              <a
                                href="https://www.facebook.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-facebook-f"></i>
                              </a>
                            </li>
                            <li className="me-2">
                              <a
                                href="https://www.twitter.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-twitter"></i>
                              </a>
                            </li>
                            <li className="me-2">
                              <a
                                href="https://www.whatsapp.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-whatsapp"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="mailto:example@example.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-solid fa-envelope"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="dz-body">
                      <div className="book-detail">
                        <ul className="book-info">
                          <li>
                            <div className="writer-info">
                              {bookDetails?.book?.author?.image_url ? (
                                <img
                                  src={bookDetails?.book?.author?.image_url}
                                  alt="Author"
                                />
                              ) : (
                                <img src={authorAvatar} alt="authorAvatar" />
                              )}
                              <Link
                                to={`/author-books/${bookDetails?.book?.author_id}`}
                              >
                                <div>
                                  <span>Author</span>
                                  {bookDetails?.book?.author?.name}
                                </div>
                                <span className="bold text-info">See More</span>
                              </Link>
                            </div>
                          </li>

                          {bookDetails?.book?.publisher !== null && (
                            <li>
                              <span>Publisher</span>
                              {bookDetails?.book?.publisher?.name}
                              <Link
                                to={`/publisher/${bookDetails?.book?.publisher_id}`}
                              >
                                {bookDetails?.publisher}
                                <span className="bold text-info">See More</span>
                              </Link>
                            </li>
                          )}

                          {bookDetails?.book?.narrator_id !== null && (
                            <li>
                              <span>Narrator</span>
                              <Link
                                to={`/narrator-books/${bookDetails?.book?.narrator?.id}`}
                              >
                                {bookDetails?.book?.narrator?.name}
                                <span className="bold text-info">See More</span>
                              </Link>
                            </li>
                          )}
                          {bookDetails?.contributor?.length !== 0 && (
                            <li>
                              <span>Contributor</span>
                              <Link onClick={handleShow}>
                                <span className="bold text-info">Open</span>
                              </Link>

                              <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Contributor</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {bookDetails?.contributor?.map((con, i) => (
                                    <span
                                      key={i}
                                      className="bold p-2 bg-warning rounded text-dark ms-2"
                                    >
                                      {con?.name}
                                    </span>
                                  ))}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    className="btn btn-primary btnhover btnhover2 ms-2"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                  {/* Add additional buttons or components here */}
                                </Modal.Footer>
                              </Modal>
                            </li>
                          )}
                        </ul>
                      </div>
                      <p className="text-1">
                        {bookDetails?.book?.short_description}
                      </p>
                      <p className="text-2">
                        {bookDetails?.book?.long_description}
                      </p>
                      <div className="book-footer">
                        <div className="price">
                          {bookDetails?.book?.reading_authorization == 1 && (
                            <h5>Free</h5>
                          )}
                          {bookDetails?.book?.reading_authorization == 2 && (
                            <h5>Subscription</h5>
                          )}

                          {bookDetails?.is_my_book === false && (
                            <>
                              {bookDetails?.book?.book_price !== null &&
                                bookDetails?.book?.book_price !== 0 && (
                                  <div>
                                    {bookDetails?.book?.discount_type == 1 ? (
                                      <h5 className="price-num">
                                        ৳
                                        {parseFloat(
                                          bookDetails?.book?.book_price
                                        ) -
                                          parseFloat(
                                            bookDetails?.book?.book_discount
                                          )}
                                        <del className="text-warning ms-2">
                                          ৳{bookDetails?.book?.book_price}
                                        </del>
                                      </h5>
                                    ) : bookDetails?.book?.discount_type ==
                                      2 ? (
                                      <h5 className="price-num">
                                        ৳
                                        {parseFloat(
                                          bookDetails?.book?.book_price
                                        ) *
                                          (1 -
                                            parseFloat(
                                              bookDetails?.book?.book_discount
                                            ) /
                                              100)}
                                        <del className="text-warning ms-2">
                                          ৳{bookDetails?.book?.book_price}
                                        </del>
                                      </h5>
                                    ) : (
                                      <h5 className="price-num">
                                        ৳ {bookDetails?.book?.book_price}
                                      </h5>
                                    )}
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                        <div className="product-num">
                          {bookDetails?.book?.reading_authorization == 1 && (
                            <>
                              {bookDetails?.book?.book_type == 3 && (
                                <>
                                  <button
                                    onClick={handleReadAudio}
                                    type="submit"
                                    className="btn btn-primary btnhover btnhover2 ms-2"
                                  >
                                    <span>Listen Now</span>
                                  </button>
                                  <button
                                    onClick={handleReadBook}
                                    type="submit"
                                    className="btn btn-primary btnhover btnhover2 ms-2"
                                  >
                                    <span>Read Now</span>
                                  </button>
                                </>
                              )}
                              {bookDetails?.book?.book_type == 2 && (
                                <button
                                  onClick={handleReadAudio}
                                  type="submit"
                                  className="btn btn-primary btnhover btnhover2 ms-2"
                                >
                                  <span>Listen Now</span>
                                </button>
                              )}
                              {bookDetails?.book?.book_type == 1 && (
                                <button
                                  onClick={handleReadBook}
                                  type="submit"
                                  className="btn btn-primary btnhover btnhover2 ms-2"
                                >
                                  <span>Read Now</span>
                                </button>
                              )}
                            </>
                          )}
                          {bookDetails?.book?.reading_authorization === 2 && (
                            <>
                              {bookDetails?.active_plan === false && (
                                <>
                                  <Link
                                    to={"/plans"}
                                    className="btn btn-secondary box-btn btnhover btnhover2"
                                  >
                                    <i className="flaticon-shopping-cart-1 m-r10"></i>{" "}
                                    Buy plan
                                  </Link>
                                </>
                              )}
                              {bookDetails?.active_plan === true && (
                                <>
                                  {bookDetails?.book?.book_type === 3 && (
                                    <>
                                      <button
                                        onClick={handleReadAudio}
                                        type="submit"
                                        className="btn btn-primary btnhover btnhover2 ms-2"
                                      >
                                        <span>Listen Now</span>
                                      </button>
                                      <button
                                        onClick={handleReadBook}
                                        type="submit"
                                        className="btn btn-primary btnhover btnhover2 ms-2"
                                      >
                                        <span>Read Now</span>
                                      </button>
                                    </>
                                  )}
                                  {bookDetails?.book?.book_type === 2 && (
                                    <button
                                      onClick={handleReadAudio}
                                      type="submit"
                                      className="btn btn-primary btnhover btnhover2 ms-2"
                                    >
                                      <span>Listen Now</span>
                                    </button>
                                  )}
                                  {bookDetails?.book?.book_type === 1 && (
                                    <button
                                      onClick={handleReadBook}
                                      type="submit"
                                      className="btn btn-primary btnhover btnhover2 ms-2"
                                    >
                                      <span>Read Now</span>
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {bookDetails?.book?.reading_authorization === 3 && (
                            <>
                              {bookDetails?.is_my_book === false && (
                                <>
                                  <Link
                                    onClick={() =>
                                      addToCartHandler(bookDetails?.book)
                                    }
                                    className="btn btn-secondary box-btn btnhover btnhover2"
                                  >
                                    <i className="flaticon-shopping-cart-1 m-r10"></i>{" "}
                                    Add to cart
                                  </Link>
                                </>
                              )}
                              {bookDetails?.is_my_book === true && (
                                <>
                                  {bookDetails?.book?.book_type === 3 && (
                                    <>
                                      <button
                                        onClick={handleReadAudio}
                                        type="submit"
                                        className="btn btn-primary btnhover btnhover2 ms-2"
                                      >
                                        <span>Listen Now e</span>
                                      </button>
                                      <button
                                        onClick={handleReadBook}
                                        type="submit"
                                        className="btn btn-primary btnhover btnhover2 ms-2"
                                      >
                                        <span>Read Now</span>
                                      </button>
                                    </>
                                  )}
                                  {bookDetails?.book?.book_type === 2 && (
                                    <button
                                      onClick={handleReadAudio}
                                      type="submit"
                                      className="btn btn-primary btnhover btnhover2 ms-2"
                                    >
                                      <span>Listen Now</span>
                                    </button>
                                  )}
                                  {bookDetails?.book?.book_type === 1 && (
                                    <button
                                      onClick={handleReadBook}
                                      type="submit"
                                      className="btn btn-primary btnhover btnhover2 ms-2"
                                    >
                                      <span>Read Now</span>
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          <div className="bookmark-btn style-1 d-none d-sm-block">
                            {bookDetails?.is_save == false ? (
                              // Render this when is_save is false
                              <>
                                <button
                                  className="p-0 m-0 border-0"
                                  onClick={() => handleSaveBook()}
                                >
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault1"
                                  >
                                    <i className="flaticon-heart"></i>
                                  </label>
                                </button>
                              </>
                            ) : (
                              <>
                                {/* Commented out, make sure this part behaves as expected */}
                                <button
                                  className="p-0 m-0 border-0"
                                  onClick={() =>
                                    handleDeleteClick(
                                      bookDetails?.is_save_book_id
                                    )
                                  }
                                >
                                  <label
                                    className="form-check-label bg-danger"
                                    htmlFor="flexCheckDefault1"
                                  >
                                    <i className="flaticon-heart"></i>
                                  </label>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8">
                <Tab.Container defaultActiveKey="chapter">
                  <div className="product-description tabs-site-button">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li">
                        <Nav.Link eventKey="chapter">
                          Chapter & Episode{" "}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey="details">Details Product</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey="review">Customer Reviews</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="chapter">
                        <Tabs
                          defaultActiveKey="eBook"
                          id="uncontrolled-tab-example"
                          className="mb-3 border-bottom-0 book-area"
                        >
                          {bookDetails?.chapters?.ebook_chapters.length > 0 && (
                            <Tab eventKey="eBook" title="eBook">
                              {bookDetails?.chapters?.ebook_chapters.map(
                                (chapter, index) => (
                                  <div
                                    key={index}
                                    className="mt-3 py-2 px-4 d-flex justify-content-between  align-items-center bg-light shadow-sm chapter-area w-75"
                                  >
                                    <div>
                                      <h4>{chapter.name}</h4>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <img src={chapterImage} alt="book" />
                                      <Button
                                        size="sm"
                                        onClick={() => handleChapter(chapter)}
                                      >
                                        Read Now
                                      </Button>
                                    </div>
                                  </div>
                                )
                              )}
                            </Tab>
                          )}
                          {bookDetails?.chapters?.audiobook_chapters.length >
                            0 && (
                            <Tab eventKey="audio-book" title="Audio Book">
                              {bookDetails?.chapters?.audiobook_chapters.map(
                                (chapter, index) => (
                                  <div
                                    key={index}
                                    className="mt-3 py-2 px-4 d-flex justify-content-between  align-items-center bg-light shadow-sm chapter-area w-75"
                                  >
                                    <div>
                                      <h4>{chapter.name}</h4>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <img src={chapterImage} alt="book" />
                                      <Button
                                        size="sm"
                                        onClick={() =>
                                          handleAudioChapter(chapter)
                                        }
                                      >
                                        Listen Now
                                      </Button>
                                    </div>
                                  </div>
                                )
                              )}
                            </Tab>
                          )}
                        </Tabs>
                      </Tab.Pane>
                      <Tab.Pane eventKey="details">
                        <table className="table border book-overview">
                          <tbody>
                            <tr>
                              <th>Book Title</th>
                              <td>{bookDetails?.book?.book_name}</td>
                            </tr>
                            <tr>
                              <th>Author</th>
                              <td>{bookDetails?.book?.author?.name}</td>
                            </tr>
                            <tr>
                              <th> Language</th>
                              <td>{bookDetails?.book?.book_name}</td>
                            </tr>
                            <tr>
                              <th>Date of Publication</th>
                              <td>
                                {month} - {month} - {year}
                              </td>
                            </tr>
                            <tr>
                              <th>Publisher</th>
                              <td>
                                <Link
                                  to={`/publisher/${bookDetails?.book?.publisher_id}`}
                                >
                                  {bookDetails?.book?.publisher?.name}
                                </Link>
                              </td>
                            </tr>
                            {/* <tr className="tags">
                              <th>Tags</th>
                              <td>
                                {bookDetails?.book?.keyword_1 &&
                                  bookDetails?.book?.keyword_1}
                                {bookDetails?.book?.keyword_2 &&
                                  ", " + bookDetails?.book?.keyword_2}
                                {bookDetails?.book?.keyword_3 &&
                                  ", " + bookDetails?.book?.keyword_3}
                                {bookDetails?.book?.keyword_4 &&
                                  ", " + bookDetails?.book?.keyword_4}
                                {bookDetails?.book?.keyword_5 &&
                                  ", " + bookDetails?.book?.keyword_5}
                                {bookDetails?.book?.keyword_6 &&
                                  ", " + bookDetails?.book?.keyword_6}
                                {bookDetails?.book?.keyword_7 &&
                                  ", " + bookDetails?.book?.keyword_7}
                                {bookDetails?.book?.keyword_8 &&
                                  ", " + bookDetails?.book?.keyword_8}
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="review">
                        <div className="clear" id="comment-list">
                          <div className="post-comments comments-area style-1 clearfix">
                            <div>
                              {bookDetails?.ratings?.map((r, index) => (
                                <CommentBlog
                                  key={index}
                                  title={r.user_name}
                                  image={r.user_profile_photo}
                                  review={r.message}
                                  rating={r.rating}
                                  date={r.created_at}
                                />
                              ))}
                            </div>
                            <div
                              className="default-form comment-respond style-1"
                              id="respond"
                            >
                              <h4
                                className="comment-reply-title"
                                id="reply-title"
                              >
                                Review Here
                              </h4>
                              <div className="clearfix">
                                <form
                                  method="post"
                                  id="comments_form"
                                  className="comment-form"
                                  noValidate
                                  onSubmit={handleReplay}
                                >
                                  <div className="rating-area">
                                    <StarRatings
                                      rating={rating}
                                      starRatedColor="#FFCE00"
                                      changeRating={(rating) =>
                                        setRating(rating)
                                      }
                                      numberOfStars={5}
                                      name="rating"
                                    />
                                  </div>
                                  <p className="comment-form-comment">
                                    <textarea
                                      id="comments"
                                      placeholder="Type Comment Here"
                                      className="form-control4"
                                      name="comment"
                                      cols="45"
                                      rows="3"
                                      required="required"
                                      value={message}
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                    ></textarea>
                                  </p>
                                  <p className="col-md-12 col-sm-12 col-xs-12 form-submit">
                                    <button
                                      id="submit"
                                      type="submit"
                                      className="submit btn btn-primary filled"
                                    >
                                      Submit Now
                                      <i className="fa fa-angle-right m-l10"></i>
                                    </button>
                                  </p>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
              <div className="col-xl-4 mt-5 mt-xl-0">
                <div className="widget">
                  <h4 className="widget-title">Related Books</h4>
                  <div className="row">
                    {bookDetails?.related_books?.map((data, index) => (
                      <div className="col-xl-12 col-lg-6" key={index}>
                        <div className="dz-shop-card style-5">
                          <div className="dz-media">
                            <Link to={`/books-detail/${data?.id}`}>
                              <img src={data?.book_image} alt="" />
                            </Link>
                            <div className="sticker w-50 pt-0 mt-0">
                              {data?.book_type == 2 && (
                                <img
                                  className="sticker-image"
                                  src={AudioBookImage}
                                  alt="Audiobook"
                                />
                              )}

                              {data?.book_type == 1 && (
                                <img
                                  className="sticker-image"
                                  src={EBookBookImage}
                                  alt="Ebook"
                                />
                              )}

                              {data?.book_type == 3 && (
                                <div>
                                  <img
                                    className="sticker-image"
                                    src={AudioBookImage}
                                    alt="Audiobook"
                                  />
                                  <img
                                    className="sticker-image"
                                    src={EBookBookImage}
                                    alt="Ebook"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dz-content">
                            <Link to={`/books-detail/${data?.id}`}>
                              <h5 className="subtitle">{data?.book_name}</h5>
                            </Link>
                            <div className="price">
                              {data?.reading_authorization == 1 ? (
                                <span className="price-num">Free</span>
                              ) : data?.reading_authorization == 2 ? (
                                <span className="price-num">Subscription</span>
                              ) : (
                                <>
                                  {data?.discount_type == 1 ? (
                                    <span className="price-num">
                                      ৳
                                      {parseFloat(data?.book_price) -
                                        parseFloat(data?.book_discount)}
                                      <del className="text-danger">
                                        ৳{data?.book_price}
                                      </del>
                                    </span>
                                  ) : data?.discount_type == 2 ? (
                                    <span className="price-num">
                                      ৳
                                      {parseFloat(data?.book_price) *
                                        (1 -
                                          parseFloat(data?.book_discount) /
                                            100)}
                                      <del className="text-danger">
                                        ৳{data?.book_price}
                                      </del>
                                    </span>
                                  ) : (
                                    <span className="price-num">
                                      ৳ {data?.book_price}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>

                            {data?.reading_authorization != 1 && (
                              <Link
                                onClick={() => addToCartHandler(data)}
                                className="btn btn-outline-primary btn-sm btnhover btnhover2"
                              >
                                <i className="flaticon-shopping-cart-1 me-2"></i>
                                Add to cart
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <section className="content-inner">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        {/* <NewsLetter /> */}
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default BookDetail;
