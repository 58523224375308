import React, { useState } from "react";

import OutsideClick from "outsideclick-react";
import ChapterIcon from "../../assets/icons/chapter_icon.svg";

function ChapterDropdown({ chapters, onChapterSelect, activeChapter }) {
  const [dropdown, setDropdown] = useState(false);

  return (
    <OutsideClick
      onOutsideClick={() => setDropdown(false)}
      className="dropdown v_dropdown"
    >
      <div onClick={() => setDropdown(true)}>
        <img src={ChapterIcon} alt="icon" width={34} height={34} />
      </div>
      {dropdown && (
        <ul className="dropdown-menu show v_dropdown-menu">
          {chapters?.map((v, i) => (
            <li>
              <div
                onClick={() => {
                  if (v.id == activeChapter) {
                    return;
                  }
                  onChapterSelect?.(v);
                  setDropdown(false);
                }}
                className={
                  "dropdown-item v_dropdown-item " +
                  (v.id == activeChapter && "active")
                }
              >
                {v?.name}
              </div>
            </li>
          ))}
        </ul>
      )}
    </OutsideClick>
  );
}

export default ChapterDropdown;
