import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SiteContext } from "../context/ContextProvider";

const Search = () => {
  const { setLoading, loading, baseUrl } = useContext(SiteContext);
  const [searchBooks, setSearchBooks] = useState([]);
  const [categoryBooks, setCategoryBooks] = useState([]);
  const [subCategoryBooks, setSubCategoryBooks] = useState([]);
  const [authorBooks, setAuthorBooks] = useState([]);
  const [publisherBooks, setPublisherBooks] = useState([]);
  const params = useParams();

  const allBooks = [
    ...searchBooks,
    ...categoryBooks,
    ...subCategoryBooks,
    ...authorBooks,
    ...publisherBooks,
  ];

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/search?search_text=${
            params?.search_text || ""
          }`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        setSearchBooks(data?.data?.books);
        setCategoryBooks(data?.data?.category_books);
        setSubCategoryBooks(data?.data?.subcategory_books);
        setAuthorBooks(data?.data?.author_books);
        setPublisherBooks(data?.data?.publisher_books);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading, params?.search_text, baseUrl]);

  return !loading ? (
    <>
      <div className="page-content bg-white">
        <div className="bg-white py-5">
          <div className="container">
            {allBooks?.length !== 0 && allBooks ? (
              <>
                <div className="text-center text-success mb-4">
                  You have {allBooks?.length} books by search
                </div>
                <div>
                  {allBooks?.map((book, i) => (
                    <Link
                      key={i}
                      to={`/books-detail/${book.id}`}
                      className="page-link prev d-flex align-items-center gap-4"
                    >
                      <img
                        src={book.book_image}
                        style={{ width: "100px" }}
                        alt="book"
                      />
                      <h5>{book.book_name}</h5>
                      <div className="price">
                        {book?.reading_authorization == 1 ? (
                          <span className="price-num">Free</span>
                        ) : book?.reading_authorization == 2 ? (
                          <span className="price-num">Subscription</span>
                        ) : (
                          <>
                            {book?.discount_type === "1" ? (
                              <span className="price-num">
                                ৳
                                {parseFloat(book?.book_price) -
                                  parseFloat(book?.book_discount)}
                              </span>
                            ) : (
                              <span className="price-num">
                                ৳
                                {parseFloat(book?.book_price) *
                                  (1 - parseFloat(book?.book_discount) / 100)}
                              </span>
                            )}

                            {book?.book_discount &&
                              book?.book_discount != 0 && (
                                <del className="ms-2">৳{book?.book_price}</del>
                              )}
                          </>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center text-danger">
                No books in this keyword! try another keyword
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Search;
