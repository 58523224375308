import React, { useState } from "react";
import FullscreenButton from "../FullscreenButton";
import OutsideClick from "outsideclick-react";
import rswitch from "rswitch";

function SettingsDropdown({ settingState, autoZoom }) {
  const [settingDropdown, setSettingDropdown] = useState(false);

  const [setting, setSetting] = settingState;

  const handlePageMode = (pageMode) => {
    const pageModeNum = getPageMode(pageMode);
    if (window?.PdfViewer?.viewer) {
      window.PdfViewer.viewer.spreadMode = pageModeNum;
      setSetting((s) => ({ ...s, pageMode }));
      setSettingDropdown(false);
    }
  };

  const handleScrollMode = (scrollMode) => {
    const scrollModeNum = getScrollMode(scrollMode);
    if (window?.PdfViewer?.viewer) {
      window.PdfViewer.viewer.scrollMode = scrollModeNum;
      setSetting((s) => ({ ...s, scrollMode }));
      setSettingDropdown(false);
    }
  };

  const increaseZoom = () => {
    let currentScale = window?.PdfViewer.viewer?.currentScale || 1.25;
    setSetting((s) => ({
      ...s,
      zoom:
        typeof s.zoom == "string"
          ? currentScale + 0.25
          : Math.min(s.zoom + 0.25, 6),
    }));
  };

  const decreaseZoom = () => {
    let currentScale = window?.PdfViewer.viewer?.currentScale || 1.25;
    setSetting((s) => ({
      ...s,
      zoom:
        typeof s.zoom == "string"
          ? currentScale - 0.25
          : Math.max(s.zoom - 0.25, 0.25),
    }));
  };

  return (
    <div className="d-flex" style={{ gap: "24px" }}>
      <FullscreenButton />
      <OutsideClick
        onOutsideClick={() => setSettingDropdown(false)}
        className="dropdown v_dropdown"
      >
        <div onClick={() => setSettingDropdown(true)}>
          <svg
            width={30}
            height={30}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 35.3C17.8 35.3 12.7 30.2 12.7 24C12.7 17.8 17.8 12.7 24 12.7C30.2 12.7 35.3 17.8 35.3 24C35.3 30.2 30.2 35.3 24 35.3ZM24 15.1C19.1 15.1 15.1 19.1 15.1 24C15.1 28.9 19.1 32.9 24 32.9C28.9 32.9 32.9 28.9 32.9 24C32.9 19.1 28.9 15.1 24 15.1Z"
              fill="#134563"
            />
            <path
              d="M27.4 47.9H20.6L18.7 42.1C17.4 41.7 16.2 41.2 15 40.6L9.50002 43.4L4.70001 38.6L7.50002 33.1C6.90002 31.9 6.30002 30.7 6.00002 29.4L0.200012 27.5V20.7L6.00002 18.8C6.40002 17.5 6.90002 16.3 7.50002 15.1L4.70001 9.60001L9.50002 4.79999L15 7.60001C16.2 7.00001 17.4 6.40001 18.7 6.10001L20.6 0.299988H27.4L29.3 6.10001C30.6 6.50001 31.8 7.00001 33 7.60001L38.5 4.79999L43.3 9.60001L40.5 15.1C41.1 16.3 41.7 17.5 42 18.8L47.8 20.7V27.5L42 29.4C41.6 30.7 41.1 31.9 40.5 33.1L43.3 38.6L38.5 43.4L33 40.6C31.8 41.2 30.6 41.8 29.3 42.1L27.4 47.9ZM22.3 45.6H25.7L27.5 40.2L28.1 40C29.6 39.6 31 39 32.4 38.2L33 37.9L38.1 40.5L40.5 38.1L37.9 33L38.2 32.4C39 31.1 39.6 29.6 40 28.1L40.2 27.5L45.6 25.7V22.3L40.2 20.5L40 19.9C39.6 18.4 39 16.9 38.2 15.6L37.9 15L40.5 9.89999L38.1 7.5L33 10.1L32.4 9.79999C31.1 8.99999 29.6 8.4 28.1 8L27.5 7.79999L25.7 2.39999H22.3L20.5 7.79999L19.9 8C18.4 8.4 17 8.99999 15.6 9.79999L15 10.1L9.90001 7.5L7.50002 9.89999L10.1 15L9.80002 15.6C9.00002 16.9 8.40002 18.4 8.00002 19.9L7.80002 20.5L2.40001 22.3V25.7L7.80002 27.5L8.00002 28.1C8.40002 29.6 9.00002 31.1 9.80002 32.4L10.1 33L7.50002 38.1L9.90001 40.5L15 37.9L15.6 38.2C16.9 39 18.4 39.6 19.9 40L20.5 40.2L22.3 45.6Z"
              fill="#134563"
            />
          </svg>
        </div>
        {settingDropdown && (
          <ul className="dropdown-menu show v_dropdown-menu dropdown-modal right">
            <li>
              <div className="text-dark">Zoom</div>
              <div className="d-flex gap-3">
                <div onClick={() => (autoZoom ? autoZoom() : increaseZoom())}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 10.5H13.5V3C13.5 2.172 12.828 1.5 12 1.5C11.172 1.5 10.5 2.172 10.5 3V10.5H3C2.172 10.5 1.5 11.172 1.5 12C1.5 12.828 2.172 13.5 3 13.5H10.5V21C10.5 21.828 11.172 22.5 12 22.5C12.828 22.5 13.5 21.828 13.5 21V13.5H21C21.828 13.5 22.5 12.828 22.5 12C22.5 11.172 21.828 10.5 21 10.5Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div onClick={() => decreaseZoom()}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.5656 10.5H4.43437C3.64219 10.5 3 11.1703 3 12C3 12.8297 3.64219 13.5 4.43437 13.5H19.5656C20.3578 13.5 21 12.8297 21 12C21 11.1703 20.3578 10.5 19.5656 10.5Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li style={{ paddingTop: "10px" }}></li>{" "}
            <li>
              <div className="text-dark">Page Mode</div>
              <div className="d-flex gap-3">
                <div onClick={() => handlePageMode("single")}>
                  <svg
                    width="16"
                    height="24"
                    viewBox="0 0 16 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 0L15.9991 0.00300096L13.3939 0C14.8327 0 15.9991 1.17545 15.9991 2.62541V21.3709C15.9991 22.8209 14.8327 23.9964 13.3939 23.9964L13.7669 24L13.766 23.9962L2.60524 23.9964C1.16642 23.9964 0 22.821 0 21.371V2.62541C0 1.17543 1.16642 0 2.60524 0H16ZM13.7669 2.25035H2.60524C2.39969 2.25035 2.23306 2.41827 2.23306 2.62541V21.371C2.23306 21.5782 2.39969 21.746 2.60524 21.746H13.7669V2.25035Z"
                      fill="#212121"
                    />
                  </svg>
                </div>
                <div onClick={() => handlePageMode("dual")}>
                  <svg
                    width="30"
                    height="24"
                    viewBox="0 0 30 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.1253 0L16.1244 0.00300096L27.3744 0.00364551C28.8245 0.00364551 30 1.17909 30 2.62905V21.3746C30 22.8246 28.8245 24 27.3744 24H13.8747L13.8738 23.9962L2.62563 23.9964C1.17555 23.9964 0 22.821 0 21.371V2.62541C0 1.17543 1.17555 0 2.62563 0H16.1253ZM27.3744 2.25399H16.1253V21.7497H27.3744C27.5814 21.7497 27.7495 21.5818 27.7495 21.3746V2.62905C27.7495 2.42192 27.5814 2.25399 27.3744 2.25399ZM13.8747 2.25035H2.62563C2.41848 2.25035 2.25054 2.41827 2.25054 2.62541V21.371C2.25054 21.5782 2.41848 21.746 2.62563 21.746H13.8747V2.25035Z"
                      fill="#212121"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li style={{ paddingTop: "10px" }}></li>
            <li>
              <div className="text-dark">Scroll Mode</div>
              <div className="d-flex gap-3">
                <div onClick={() => handleScrollMode("vertical")}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 0C17.552 0 18 0.448 18 1V17C18 17.552 17.552 18 17 18H1C0.448 18 0 17.552 0 17V1C0 0.448 0.448 0 1 0H17ZM16 2H2V7.999V8V10V16H16V10V8V7.999V2ZM9 3L12 6H10V12H12L9 15L6 12H8V6H6L9 3Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div onClick={() => handleScrollMode("horizontal")}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 22.6667C24 23.4027 23.4027 24 22.6667 24L1.33333 24C0.597334 24 4.07548e-07 23.4027 4.3972e-07 22.6667L1.37223e-06 1.33333C1.4044e-06 0.597332 0.597335 -1.02296e-06 1.33333 -9.90791e-07L22.6667 -5.82818e-08C23.4027 -2.61103e-08 24 0.597333 24 1.33333L24 22.6667ZM21.3333 21.3333L21.3333 2.66667L13.3347 2.66667L13.3333 2.66667L10.6667 2.66667L2.66667 2.66667L2.66667 21.3333L10.6667 21.3333L13.3333 21.3333L13.3347 21.3333L21.3333 21.3333ZM20 12L16 16L16 13.3333L8 13.3333L8 16L4 12L8 8L8 10.6667L16 10.6667L16 8L20 12Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        )}
      </OutsideClick>
    </div>
  );
}

export default SettingsDropdown;

const getPageMode = (key) =>
  rswitch(key, {
    single: 0,
    dual: 1,
    "": 0,
  });
const getScrollMode = (key) =>
  rswitch(key, {
    vertical: 0,
    horizontal: 1,
    "": 0,
  });
