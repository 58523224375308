import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Navigation, Pagination } from "swiper";
import "swiper/css";

//Images

import { SiteContext } from "../../context/ContextProvider";
import book1 from "./../../assets/images/books/large/bigbook1.jpg";
import book2 from "./../../assets/images/books/large/bigbook2.jpg";

// import Swiper core and required modules

const dataBlog = [
  { image: book1, title: "A Heavy LIft" },
  { image: book2, title: "Pushing Clouds" },
  { image: book1, title: "A Heavy LIft" },
  { image: book2, title: "Pushing Clouds" },
  { image: book1, title: "A Heavy LIft" },
  { image: book2, title: "Pushing Clouds" },
];

function FeaturedSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);
  const { loading, baseUrl } = useContext(SiteContext);
  const [featuredBooks, setFeaturedBooks] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${baseUrl}/api/home-featured`)
      .then((response) => response.json())
      .then((data) => {
        setFeaturedBooks(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [baseUrl]);

  const handleReadBook = (book_id) => {
    navigate(`/books-detail/${book_id}`);
  };

  return !loading ? (
    <>
      {featuredBooks?.featured_books?.length !== 0 && (
        <>
      <section className="content-inner-1 bg-grey reccomend">
        <div className="container">
          <div className="section-head book-align">
            <h2 className="title mb-0">{featuredBooks?.name}</h2>
          </div>
        </div>
        <div className="container">
          <Swiper
            className="swiper-container books-wrapper-2 swiper-three"
            loop={true}
            parallax={true}
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={90}
            speed={2000}
            pagination={{
              el: ".swiper-pagination-three",
              clickable: true,
            }}
            autoplay={{
              delay: 1000,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            modules={[Navigation, Pagination]}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              1200: {
                slidesPerView: 1,
              },
              1680: {
                slidesPerView: 1,
              },
            }}
          >
            {featuredBooks?.featured_books?.map((book, ind) => (
              <SwiperSlide key={ind}>
                <div className="books-card style-2">
                  <div className="dz-media">
                  <Link to={`/books-detail/${book.id}`}> <img src={book?.book_image} alt="book" /> </Link>
                  </div>
                  <div className="dz-content">
                    <h2 className="title">{book?.book_name}</h2>
                    <p className="text">{book?.short_description}</p>
                    <div className="price">
                      {book?.reading_authorization == 1 ? (
                        <span className="price-num">Free</span>
                      ) : book?.reading_authorization == 2 ? (
                        <span className="price-num">Subscription</span>
                      ) : (
                        <>
                          {book?.discount_type == 1 ? (
                            <span className="price-num">
                              ৳
                              {parseFloat(book?.book_price) -
                                parseFloat(book?.book_discount)}
                                <del>৳{book?.book_price}</del>
                            </span>
                          ) : book?.discount_type == 2 ? (
                            <span className="price-num">
                              ৳{parseFloat(book?.book_price) *
                                (1 - parseFloat(book?.book_discount) / 100)}
                                <del>৳{book?.book_price}</del>
                            </span>
                          ) : (
                            <span className="price-num">
                              ৳ {book?.book_price}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div className="bookcard-footer">
                      <button
                        onClick={() => handleReadBook(book.id)}
                        type="submit"
                        className="btn btn-primary btnhover btnhover2 ms-2"
                      >
                        <span>Details</span>
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="pagination-align style-2">
              <div className="swiper-button-prev" ref={navigationPrevRef}>
                <i className="fa-solid fa-angle-left"></i>
              </div>
              <div
                className="swiper-pagination-three"
                ref={paginationRef}
              ></div>
              <div className="swiper-button-next" ref={navigationNextRef}>
                <i className="fa-solid fa-angle-right"></i>
              </div>
            </div>
          </Swiper>
          
        </div>
          </section>
          </>
      )}
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default FeaturedSlider;
