import React, { useContext, useEffect, useState } from "react";
import { SiteContext } from "../context/ContextProvider";

const WalletHistory = () => {
  const { isUser, loading, setLoading, baseUrl } = useContext(SiteContext);
  const [transitionHistory, setTransitionHistory] = useState(null);
  const [bookDetails, setBookDetails] = useState([]);

  useEffect(() => {
    const fetchBookDetails = async (bookId) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/book/${bookId}?user_id=${isUser?.user?.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("_token")
              )}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setBookDetails((prevBookDetails) => [...prevBookDetails, data]);
        } else {
          // Handle errors here
          console.error("Failed to fetch book details");
        }
      } catch (error) {
        console.error("Error while fetching book details:", error);
      }
    };

    setBookDetails([]);
    transitionHistory?.forEach((item) => {
      fetchBookDetails(item.book_id);
    });
  }, [transitionHistory, isUser?.user?.id, baseUrl]);

  const totalBalance = transitionHistory?.reduce(
    (total, item) => total + parseInt(item.balance),
    0
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("_token"));

        if (!token) {
          setLoading(false);
          return;
        }
        const response = await fetch(
          `${baseUrl}/api/wallet/transaction`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("_token")
              )}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setLoading(false);
        setTransitionHistory(data?.data?.point_wallets);
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading, baseUrl]);

  return !loading ? (
    <>
      <div className="row">
        <h4 className="text-center py-4">My wallet history</h4>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Book</th>
            <th scope="col">Balence</th>
          </tr>
        </thead>
        <tbody>
          {bookDetails?.map((item, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>
                <img
                  style={{ width: "60px", marginRight: "10px" }}
                  src={item?.data?.book?.book_image}
                  alt="Book"
                />
                {item?.data?.book?.book_name}
              </td>
              <td>{item?.data?.book?.point}</td>
            </tr>
          ))}
          <tr className="bg-dark text-light">
            <th scope="row"></th>
            <td>Total</td>
            <td>{totalBalance}</td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default WalletHistory;
