import React, { useContext } from 'react';
import { SiteContext } from '../context/ContextProvider';
import { Link } from 'react-router-dom';

const MyDashboard = () => {
    const  {isUser, savedBooks, myBooks, transition} = useContext(SiteContext);
    
    
    return (
        <>
            <div className="shop-bx shop-profile">
                <div className="shop-bx-title clearfix">
                    <h5 className="text-uppercase">Welcome {isUser?.user?.name}</h5>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body" style={{backgroundColor:"#E1E8F0"}}>
                                <div className='d-flex justify-content-between mb-4'>
                                    <h5 className="card-title">Orders</h5>
                                    <h1 className=" h1">{transition ? transition.length : 0}</h1>
                                </div>
                                <Link to="/transition" className="btn btn-primary btnhover w-100 me-2 float-end">View Details</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body" style={{backgroundColor:"#E1E8F0"}}>
                                <div className='d-flex justify-content-between mb-4'>
                                    <h5 className="card-title">My books</h5>
                                    <h1 className=" h1">{myBooks ? myBooks.length : 0}</h1>
                                </div>
                                <Link to="/mybooks" className="btn btn-primary btnhover w-100 me-2 float-end">View Details</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body" style={{backgroundColor:"#E1E8F0"}}>
                                <div className='d-flex justify-content-between mb-4'>
                                    <h5 className="card-title">Wallet Balence</h5>
                                    <h1 className=" h1">{isUser?.profile?.wallet ? isUser?.profile?.wallet : 0}</h1>
                                </div>
                                <Link to="/wallet-history" className="btn btn-primary btnhover w-100 me-2 float-end">View Details</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body" style={{backgroundColor:"#E1E8F0"}}>
                                <div className='d-flex justify-content-between mb-4'>
                                    <h5 className="card-title">Saved Books</h5>
                                    <h1 className=" h1">{savedBooks ? savedBooks.length : 0}</h1>
                                </div>
                                <Link to="/saved-books" className="btn btn-primary btnhover w-100 me-2 float-end">View Details</Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </>
    );
};

export default MyDashboard;