import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import EpubViewer from "react-epub";
import "react-epub/dist/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL } from "./constant";
const useBookIdQuery = () => {
  const [searchParams] = useSearchParams();
  const book_id = searchParams.get("book_id");
  const chapter_id = searchParams.get("chapter_id");
  const ttn = searchParams.get("ttn");
  return { book_id, chapter_id, ttn };
};

function AppEpubBookView() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { book_id, chapter_id, ttn } = useBookIdQuery();
  const [epubUrl, setEpubUrl] = useState("");
  const [bookDetails, setBookDetails] = useState(null);

  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    if (book_id) {
      axios
        .get(API_URL + "/book-by-chapter/" + book_id + "?type=ebook", {
          headers: { Authorization: `Bearer ${ttn}` },
        })
        .then((v) => {
          const data = v.data?.data;
          const isChapter = data?.chapters && data?.chapters?.length > 0;
          setBookDetails(data);
          setHighlights([]);
          if (isChapter && chapter_id) {
            const findChapters = data?.chapters?.find(
              (v) => v.id == chapter_id
            );

            if (!findChapters) {
              navigate(`/app/book/epub/show?book_id=${book_id}&ttn=${ttn}`);
            } else {
              const pdf_url = findChapters?.pdf_url;
              setEpubUrl(pdf_url);
              axios
                .get(
                  `${API_URL}/chapter-highlight-note?book_id=${book_id}&chapter_id=${chapter_id}`,
                  {
                    headers: { Authorization: `Bearer ${ttn}` },
                  }
                )
                .then((v) => {
                  const webText =
                    v.data?.data?.chapter_highlight_or_note?.[0]?.web_text;
                  if (webText) {
                    try {
                      let data = JSON.parse(webText);
                      setHighlights(data);
                    } catch (e) {}
                  }
                  setLoading(false);
                });
            }
          } else {
            const pdfUrl = data?.book?.pdf_url;
            setEpubUrl(pdfUrl);
            axios
              .get(API_URL + "/highlight-note?book_id=" + book_id, {
                headers: { Authorization: `Bearer ${ttn}` },
              })
              .then((v) => {
                const webText = v.data?.data?.highlight_or_note?.[0]?.web_text;

                if (webText) {
                  try {
                    let data = JSON.parse(webText);
                    setHighlights(data);
                  } catch (e) {}
                }
                setLoading(false);
              })
              .catch(() => {});
          }
        });
    } else {
      navigate(-1);
    }
  }, [book_id, chapter_id]);

  const updateHighlights = (h) => {
    if (chapter_id) {
      axios
        .post(
          API_URL + "/chapter-save-highlight-note",
          {
            book_id,
            chapter_id,
            web_text: JSON.stringify(h || highlights),
          },
          {
            headers: { Authorization: `Bearer ${ttn}` },
          }
        )
        .catch(() => {});
    } else {
      axios
        .post(
          API_URL + "/save-highlight-note",
          {
            book_id,
            web_text: JSON.stringify(h || highlights),
          },
          {
            headers: { Authorization: `Bearer ${ttn}` },
          }
        )
        .catch(() => {});
    }
  };

  const handleNewHighlight = (highlight) => {
    let findHighlight = highlights.find((v) => v.cfi === highlight.cfi);

    if (findHighlight) {
      const newHighlights = highlights.map((v) => {
        if (v.cfi === highlight.cfi) {
          v.noteTxt = highlight.noteTxt;
        }
        return v;
      });
      setHighlights(newHighlights);
      updateHighlights(newHighlights);
      return;
    }

    let newH = [...highlights, highlight];
    setHighlights(newH);
    updateHighlights(newH);
  };

  const deleteHighlight = (cfi) => {
    const filter = highlights.filter((v) => v.cfi !== cfi);
    setHighlights(filter);
    updateHighlights(filter);
  };

  const updateHighlight = (cfi, noteTxt) => {
    const newHighlights = highlights.map((v) => {
      if (v.cfi === cfi) {
        v.noteTxt = noteTxt;
      }
      return v;
    });

    setHighlights(newHighlights);
    updateHighlights(newHighlights);
  };

  const isChapter = bookDetails?.chapters && bookDetails?.chapters?.length > 0;

  const activeChapter = useMemo(() => {
    if (isChapter) {
      return bookDetails?.chapters?.find((v) => v.id == chapter_id);
    } else {
      return {};
    }
  }, [chapter_id, bookDetails, isChapter]);

  const handleChapterSelect = (v) => {
    navigate(
      `/app/book/epub/show?book_id=${book_id}&chapter_id=${v.id}&ttn=${ttn}`
    );
  };

  if (loading) return null;

  return (
    <div className="user-select app-epub-view" style={{ userSelect: "none" }}>
      <EpubViewer
        epubSrc={epubUrl}
        highlights={highlights}
        onDeleteHighlight={deleteHighlight}
        onNewHightlight={handleNewHighlight}
        onUpdateHighlight={updateHighlight}
        chapters={bookDetails?.chapters}
        activeChapter={chapter_id}
        onChapterSelect={handleChapterSelect}
        header={`${bookDetails?.book?.name || ""} ${
          activeChapter ? `- ${activeChapter?.name}` : ""
        }`}
        onBackClick={() => navigate(`/books-detail/${book_id}`)}
        // onChapterClick={}
        // onShareQuote={(text)=> do something}
      />
    </div>
  );
}

export default AppEpubBookView;
