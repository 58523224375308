import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import { SiteContext } from "../context/ContextProvider";
import CounterSection from "../elements/CounterSection";
import BookCard from "../layouts/BookCard";



function Publisher() {
  useEffect(() => {
    document.title = "ReadersFM | Publisher Books";
  }, []);

  const [publisherData, setPublisherData] = useState(null);
  const  {setLoading, loading, baseUrl} = useContext(SiteContext);
  const { id } = useParams();

  // pagination start  
  const [currentPage, setCurrentPage] = useState(`${baseUrl}/api/publisher/${id}`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${currentPage}`
        );
        const data = await response.json();
        setPublisherData(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, setLoading]);

  const totalPages = publisherData?.last_page;

  const handlePageClick = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    
  };

  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      
      <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
        <Link to="#" style={publisherData?.current_page === i ? { backgroundColor: '#274898', color: '#fff',pointerEvents: 'none' }:null} className={`page-link`} onClick={() => handlePageClick(`${baseUrl}/api/books?page=`+i)}>
          {i}
        </Link>
      </li>
    );
  }
// pagination end  



 


  return !loading ? (
    <>
      <div className="page-content">
        <section className="mb-4">
          <div className="container">

            <div className="page-content bg-white">
              <div className="content-block">
                <section className="py-4 bg-white">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4">
                        <div className="sticky-top">
                          <div className="shop-account">
                            <div className="account-detail text-center">
                              <div className="my-image">
                                <Link to={"#"}>
                                  <img
                                    alt="profile"
                                    src={
                                      publisherData?.publisher_details?.profile_photo ? publisherData?.publisher_details?.profile_photo : '/avatar-.jpg'
                                    }
                                  />
                                </Link>
                              </div>
                              <div className="account-title">
                                <div className="">
                                  <h4 className="m-b5">
                                    <Link to="#">
                                      {publisherData?.publisher_details?.name}
                                    </Link>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </section>
              </div>
            </div>


            <div className="d-flex justify-content-between align-items-center">
              <h4 className="title pt-2">Publisher Books</h4>
            </div>

            <div className="row book-grid-row">
               {
                publisherData?.data?.length === 0 && (
                  <span className="text-danger">No data found!</span>
                )
              }
              {publisherData?.books?.data?.map((book, i) => (
                <BookCard key={i} book={book} height={"350px"}/>
              ))}
            </div>
            <div className="row page mt-0">
              <div className="col-md-6">
                <p className="page-text">Showing {publisherData?.to} from {publisherData?.total} data</p>
              </div>
              <div className="col-md-6">
                <nav aria-label="Blog Pagination">
                  <ul className="pagination style-1 p-t20">
                    {/* pagination start  */}
                    <li className="page-item">
                      {publisherData?.prev_page_url && (
                        <Link
                          to={"#"}
                          className="page-link prev"
                          onClick={() => handlePageClick(publisherData?.prev_page_url)}
                        >
                          Prev
                        </Link>
                      )}

                    </li>

                    {paginationItems}

                   

                    <li className="page-item">
                      {publisherData?.next_page_url && (
                        <Link to={"#"} className="page-link next" onClick={() => handlePageClick(publisherData?.next_page_url)}>
                        Next
                      </Link>
                      )}
                      
                    </li>
                    {/* pagination end  */}
                  </ul>
                </nav>
              </div>
            </div>


            


          
            
          </div>
        </section>
        <div className="bg-white py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <section className="content-inner">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        {/* <NewsLetter /> */}
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default Publisher;