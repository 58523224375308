import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//Components
import { SiteContext } from "../context/ContextProvider";

function ShopCheckout() {
  const { isUser, loading, setLoading, baseUrl } = useContext(SiteContext);
  const [country, setCountry] = useState(null);
  const [division, setDivision] = useState(null);
  const [district, setDistrict] = useState(null);
  const [thana, setThana] = useState(null);
  const location = useLocation();
  const { plan } = location?.state || null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        console.log(token);
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }
        const response = await fetch(`${baseUrl}/api/country`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setCountry(data?.success?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDivision = async () => {
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        const selectedCountry = country?.find(
          (countryData) => countryData.name === isUser?.profile?.country
        );
        const countryId = selectedCountry?.id;
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }
        const response = await fetch(`${baseUrl}/api/division/${countryId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setDivision(data?.success?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDivision();
  }, [isUser?.profile?.country, country, baseUrl]);

  useEffect(() => {
    const fetchDistrict = async () => {
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        const selectedDivision = division?.find(
          (divisionData) => divisionData.name === isUser?.profile?.division
        );
        const divisionId = selectedDivision?.id;
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }
        const response = await fetch(`${baseUrl}/api/district/${divisionId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setDistrict(data?.success?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDistrict();
  }, [isUser?.profile?.division, division, baseUrl]);

  useEffect(() => {
    const fetchThana = async () => {
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        const selectedDistrict = district?.find(
          (districtData) => districtData.name === isUser?.profile?.district
        );
        const districtId = selectedDistrict?.id;
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }
        const response = await fetch(`${baseUrl}/api/thana/${districtId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setThana(data?.success?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchThana();
  }, [isUser?.profile?.district, district, baseUrl]);

  const goToPayment = () => {
    const inputCountry = document.querySelector("#country").value;
    const inputDivision = document.querySelector("#division").value;
    const inputDistrict = document.querySelector("#district").value;
    const inputThana = document.querySelector("#thana").value;
    const inputAddress = document.querySelector("input[name='address']").value;
    const isSaveCheckbox = document.querySelector("input[name='isSave']");
    const isSaveValue = isSaveCheckbox.checked;
    const policyCheck = document.querySelector("input[name='check']");
    const policyStatus = policyCheck.checked;

    if (inputCountry === "-Select country-") {
      document.querySelector(
        "#countryMsg"
      ).innerHTML = `<p class="text-danger">Country field is required!</p>`;
    } else {
      document.querySelector("#countryMsg").innerHTML = "";
    }
    if (inputDivision === "-Select division-") {
      document.querySelector(
        "#divisionMsg"
      ).innerHTML = `<p class="text-danger">Division field is required!</p>`;
    } else {
      document.querySelector("#divisionMsg").innerHTML = "";
    }
    if (inputDistrict === "-Select district-") {
      document.querySelector(
        "#districtMsg"
      ).innerHTML = `<p class="text-danger">District field is required!</p>`;
    } else {
      document.querySelector("#districtMsg").innerHTML = "";
    }
    if (inputThana === "-Select thana-") {
      document.querySelector(
        "#thanaMsg"
      ).innerHTML = `<p class="text-danger">Thana field is required!</p>`;
    } else {
      document.querySelector("#thanaMsg").innerHTML = "";
    }
    if (inputAddress === "") {
      document.querySelector(
        "#addressMsg"
      ).innerHTML = `<p class="text-danger">Address field is required!</p>`;
    } else {
      document.querySelector("#addressMsg").innerHTML = "";
    }
    if (policyStatus === false) {
      document.querySelector(
        "#checkMsg"
      ).innerHTML = `<p class="text-danger">This is required!</p>`;
    } else {
      document.querySelector("#checkMsg").innerHTML = "";
    }

    if (
      inputCountry !== "-Select division-" &&
      inputDivision !== "-Select division-" &&
      inputDistrict !== "-Select district-" &&
      inputThana !== "-Select thana-" &&
      inputAddress !== "" &&
      policyStatus === true
    ) {
      setLoading(true);

      const trx_id =
        Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;

      const payInput = {
        email: isUser?.user?.email || "Not input",
        phone: isUser?.user?.phone || "Not input",
        country: inputCountry,
        division: inputDivision,
        district: inputDistrict,
        thana: inputThana,
        address: inputAddress,
        is_save: isSaveValue,
        desc: "Plan purchase",
        trx_id: trx_id,
        total: plan.price,
        payable: plan.price,
        discount: 0,
        coupon: 0,
        type: 2,
        plan_id: plan?.id,
      };

      fetch(`${baseUrl}/api/order/pay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
        body: JSON.stringify(payInput),
      })
        .then((res) => res.json())
        .then((data) => {
          localStorage.setItem("_pay_type", 1);
          setLoading(false);
          window.location.href = data.payment_url;
        })
        .catch((error) => {});
    } else {
      console.log("not ok");
    }
  };

  const countryChangeHandler = async (name) => {
    // Find the id based on the name
    const selectedCountry = country?.find(
      (countryData) => countryData.name === name
    );
    if (selectedCountry) {
      const countryId = selectedCountry?.id;
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }

        const response = await fetch(`${baseUrl}/api/division/${countryId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setDivision(data?.success?.data);
      } catch (error) {
        console.error("Error fetching division data:", error);
      }
    } else {
      console.error(`Country with name '${name}' not found`);
    }
  };

  const divisionChangeHandler = async (name) => {
    // Find the id based on the name
    const selectedDivision = division?.find(
      (divisionData) => divisionData.name === name
    );
    if (selectedDivision) {
      const divisionId = selectedDivision?.id;
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }

        const response = await fetch(`${baseUrl}/api/district/${divisionId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setDistrict(data?.success?.data);
      } catch (error) {
        console.error("Error fetching division data:", error);
      }
    } else {
      console.error(`Country with name '${name}' not found`);
    }
  };

  const districtChangeHandler = async (name) => {
    // Find the id based on the name
    const selectedDistrict = district?.find(
      (districtData) => districtData.name === name
    );
    if (selectedDistrict) {
      const districtId = selectedDistrict?.id;
      try {
        // Check if _token exists in localStorage
        const token = JSON.parse(localStorage.getItem("_token"));
        if (!token) {
          // Handle the case where token is not present
          console.error("Token not found in localStorage");
          return;
        }

        const response = await fetch(`${baseUrl}/api/thana/${districtId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setThana(data?.success?.data);
      } catch (error) {
        console.error("Error fetching division data:", error);
      }
    } else {
      console.error(`Country with name '${name}' not found`);
    }
  };

  return !loading ? (
    <>
      <div className="page-content">
        {/* <PageTitle  parentPage="Shop" childPage="Cart" /> */}
        <section className="content-inner shop-account">
          {/* <!-- Product --> */}
          {true ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <h4 className="widget-title">Billing Information</h4>
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <select
                            id="country"
                            className="form-control"
                            onChange={(e) => {
                              countryChangeHandler(e.target.value);
                            }}
                          >
                            <option>-Select country-</option>
                            {country?.map((countryData) => (
                              <option
                                selected={
                                  countryData?.name === isUser?.profile?.country
                                }
                                key={countryData?.id}
                                value={countryData?.name}
                              >
                                {countryData?.name}
                              </option>
                            ))}
                          </select>
                          <span id="countryMsg"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <select
                            id="division"
                            className="form-control"
                            onChange={(e) => {
                              divisionChangeHandler(e.target.value);
                            }}
                          >
                            <option>-Select division-</option>
                            {division?.map((data) => (
                              <option
                                selected={
                                  data?.name === isUser?.profile?.division
                                }
                                key={data?.id}
                                value={data?.name}
                              >
                                {data?.name}
                              </option>
                            ))}
                          </select>
                          <span id="divisionMsg"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <select
                            id="district"
                            className="form-control"
                            onChange={(e) => {
                              districtChangeHandler(e.target.value);
                            }}
                          >
                            <option>-Select district-</option>
                            {district?.map((data) => (
                              <option
                                selected={
                                  data?.name === isUser?.profile?.district
                                }
                                key={data?.id}
                                value={data?.name}
                              >
                                {data?.name}
                              </option>
                            ))}
                          </select>
                          <span id="districtMsg"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <select id="thana" className="form-control">
                            <option>-Select thana-</option>
                            {thana?.map((data) => (
                              <option
                                selected={data?.name === isUser?.profile?.thana}
                                key={data?.id}
                                value={data?.name}
                              >
                                {data?.name}
                              </option>
                            ))}
                          </select>
                          <span id="thanaMsg"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <input
                            id="phone"
                            defaultValue={isUser?.user?.phone}
                            className="form-control"
                            placeholder="Your phone"
                            type="phone"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <input
                            name="address"
                            id="address"
                            className="form-control"
                            placeholder="Address"
                            type="text"
                            defaultValue={isUser?.profile?.address}
                          />
                          <span id="addressMsg"></span>
                        </div>
                      </div>
                      <div className="form-group d-flex mb-2 mt-0 items-center gap-2">
                        <input
                          type="checkbox"
                          name="isSave"
                          id="isSaveCheckbox"
                          className="mr-3"
                        />
                        <label htmlFor="isSaveCheckbox">Save info</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="widget">
                      <h4 className="widget-title">Plan details</h4>`
                      <table className="table-bordered check-tbl m-b25">
                        <tbody>
                          <tr>
                            <td>Plan name</td>
                            <td>{plan?.name}</td>
                          </tr>
                          <tr>
                            <td>Price</td>
                            <td className="text-success">৳ {plan.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group mt-0">
                      <div className="d-flex mb-2 mt-0 items-center gap-2">
                        <input
                          className="me-1 mt-2"
                          type="checkbox"
                          name="check"
                          id="check"
                          required
                        />
                        <label htmlFor="check">
                          I agree to the{" "}
                          <Link to={"/terms-and-conditions"}>
                            terms and conditions
                          </Link>
                          ,
                          <Link to={"/return-refund-policy"}>
                            {" "}
                            return and refund policy
                          </Link>{" "}
                          &<Link to={"/privacy-policy"}> privacy policy</Link>
                        </label>
                      </div>
                      <span id="checkMsg"></span>
                      <button
                        onClick={goToPayment}
                        type="submit"
                        className="btn btn-primary btnhover btnhover2 ms-2"
                      >
                        <span>Proceed to Checkout</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center text-danger">
              Your cart is empty !{" "}
              <Link
                to="/all-books"
                className="btn btn-primary btnhover"
                type="button"
              >
                Return to shop
              </Link>
            </div>
          )}

          {/* <!-- Product END --> */}
        </section>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default ShopCheckout;
