import React, { useEffect } from 'react';
import pdfjs from 'pdfjs-dist/webpack';


const PdfJsView = () => {
    useEffect(()=>{
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
        const pdfUrl = "Unit-01.pdf";
        const pdfContainer = document.getElementById('pdf-container');
        const loadingTask = pdfjs.getDocument(pdfUrl);

        loadingTask.promise
        .then((pdf) => {
          // Fetch the first page
          return pdf.getPage(1);
        })
        .then((page) => {
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
  
          // Prepare canvas using PDF page dimensions
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
  
          // Render PDF page into canvas context
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
  
          page.render(renderContext);
  
          // Append canvas to the DOM
          pdfContainer.appendChild(canvas);
        })
        .catch((error) => {
          console.error('Error loading PDF:', error);
        });
    },[])
  return (
    <div id="pdf-container">
      
    </div>
  )
}

export default PdfJsView
