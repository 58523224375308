import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import BookCard from "../../layouts/BookCard";
import { SiteContext } from "../../context/ContextProvider";
import { Link } from "react-router-dom";

function CategoryBookSlider() {
  const { baseUrl } = useContext(SiteContext);
  const [categoryBooks, setCategoryBooks] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/home-category-books`
        );
        const data = await response.json();
        setCategoryBooks(data?.data?.home_category_book);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl]);

  return (
      <>
          {categoryBooks?.length !== 0 && (
              <>
                {categoryBooks?.map((catBook, ind) => (
                    <section key={ind} className="content-inner-2">
                        <div className="container">
                            <div className="px-2 py-4 px-md-4 py-md-3 shadow-sm rounded" style={{backgroundColor:"#DDDCDD"}}>
                                {catBook?.books?.length !== 0 && (
                                    <>
                                        <div className="section-head book-align">
                                            <h2 className="title mb-0">{catBook?.name}</h2>
                                            <div className="pagination-align style-1">
                                                <Link to={`/category-books/${catBook?.id}`}
                                                    className="btn btn-primary btnhover btnhover2 ms-2"
                                                >
                                                    <span>View all</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <Swiper
                                            className="swiper-container  book-swiper"
                                            speed={1500}
                                            parallax={true}
                                            slidesPerView={5}
                                            spaceBetween={30}
                                            autoHeight={true}
                                            loop={true}
                                            pagination={{
                                            el: ".swiper-pagination-two",
                                            clickable: true,
                                            }}
                                            autoplay={{
                                            delay: 3000,
                                            }}
                                            onSwiper={(swiper) => {
                                            setTimeout(() => {
                                                swiper.navigation.destroy();
                                                swiper.navigation.init();
                                                swiper.navigation.update();
                                            });
                                            }}
                                            modules={[Navigation, Pagination]}
                                            breakpoints={{
                                            1200: {
                                                slidesPerView: 5,
                                            },
                                            1191: {
                                                slidesPerView: 4,
                                            },
                                            767: {
                                                slidesPerView: 3,
                                            },
                                            591: {
                                                slidesPerView: 1,
                                                centeredSlides: true,
                                            },
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 15,
                                                centeredSlides: true,
                                            },
                                            }}
                                        >
                                            {catBook?.books?.map((item, ind) => (
                                            <SwiperSlide key={ind}>
                                                <BookCard book={item} height={"280px"} />
                                            </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                )}
                            </div>
                            
                        </div>
                    </section>
                ))}
              </>
          )}  
      
    </>
  );
}
export default CategoryBookSlider;
