import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../context/ContextProvider";

const proiflePages = [
  { to: "/shop-cart", icons: "flaticon-shopping-cart-1", name: "My Cart" },
  { to: "/mybooks", icons: "fas fa-book", name: "My Books" },
  {
    to: "/transition",
    icons: "fas fa-money-check-alt",
    name: "Transition History",
  },
  { to: "/privacy-policy", icons: "fa fa-key", name: "Privacy Policy" },
  { to: "/delete-my-account", icons: "fa fa-trash", name: "Accounts" },
  /* { to: "/help-desk", icons: "far fa-id-card", name: "Help Desk" }, */
  { to: "/shop-login", icons: "fas fa-sign-out-alt", name: "Log Out" },
];

const UserSidebar = () => {
  const { isUser } = useContext(SiteContext);

  return (
    <>
      <div className="sticky-top">
        <div className="shop-account">
          <div className="account-detail text-center">
            <div className="my-image">
              <Link to={"#"}>
                <img
                  className="w-100 h-100"
                  alt="profile"
                  src={
                    isUser?.profile?.profile_photo
                      ? isUser?.profile?.profile_photo
                      : "/avatar-.jpg"
                  }
                />
              </Link>
            </div>
            <div className="account-title">
              <div className="">
                <h4 className="m-b5">
                  <Link to={"#"}>{isUser?.user?.name}</Link>
                </h4>
                <p className="m-b0">
                  <Link to={"#"}>{isUser?.user?.role}</Link>
                </p>
              </div>
            </div>
          </div>
          <ul className="account-list bg-light">
            <li>
              <Link to={"/my-dashboard"} className="active">
                <i className="fas fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to={"/my-profile"} className="">
                <i className="far fa-user" aria-hidden="true"></i>
                <span>Profile</span>
              </Link>
            </li>
            {proiflePages.map((item, ind) => (
              <li key={ind}>
                <Link to={item.to}>
                  <i className={item.icons}></i>
                  <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserSidebar;
