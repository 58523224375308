import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AudioBookImage from "../assets/images/Audiobook.svg";
import EBookBookImage from "../assets/images/E-Book.svg";
import { SiteContext } from "../context/ContextProvider";

const BookCard = (props) => {
  const { addToCartHandler } = useContext(SiteContext);
  const { book, height } = props;

  return (
    <>
      <div className="col-book style-1">
        <div className="dz-shop-card style-1 border-0" style={{ padding: 0 }}>
          <div className="dz-media">
            <Link to={`/books-detail/${book.id}`}>
              <img
                src={book.book_image}
                // style={{"height": height}}
                alt="book"
                className="rounded"
              />
            </Link>
            <div className="sticker">
              {book?.book_type == 2 && (
                <img
                  className="sticker-image"
                  src={AudioBookImage}
                  alt="Audiobook"
                />
              )}

              {book?.book_type == 1 && (
                <img
                  className="sticker-image"
                  src={EBookBookImage}
                  alt="Ebook"
                />
              )}

              {book?.book_type == 3 && (
                <div>
                  <img
                    className="sticker-image"
                    src={AudioBookImage}
                    alt="Audiobook"
                  />
                  <img
                    className="sticker-image"
                    src={EBookBookImage}
                    alt="Ebook"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="dz-content">
            <h6 className="title">
              <Link to={`/books-detail/${book?.id}`}>
                {book?.book_name.length > 20
                  ? `${book?.book_name.slice(0, 20)}..`
                  : book?.book_name}
              </Link>
            </h6>
            <ul className={`dz-rating`}>
              <li>
                <i className="flaticon-star text-yellow"></i>
              </li>
              <li>
                <i className="flaticon-star text-yellow"></i>
              </li>
              <li>
                <i className="flaticon-star text-yellow"></i>
              </li>
              <li>
                <i className="flaticon-star text-yellow"></i>
              </li>
              <li>
                <i className="flaticon-star text-yellow"></i>
              </li>
            </ul>
            <div className="book-footer">
              <div className="price">
                {book?.reading_authorization == 1 ? (
                  <span className="price-num">Free</span>
                ) : book?.reading_authorization == 2 ? (
                  <span className="price-num">Subscription</span>
                ) : (
                  <>
                    {book?.discount_type == 1 ? (
                      <span className="price-num">
                        ৳
                        {parseFloat(book?.book_price) -
                          parseFloat(book?.book_discount)}
                          <del>৳{book?.book_price}</del>
                      </span>
                    ) : book?.discount_type == 2 ? (
                      <span className="price-num">
                        ৳{parseFloat(book?.book_price) *
                          (1 - parseFloat(book?.book_discount) / 100)}
                          <del>৳{book?.book_price}</del>
                      </span>
                    ) : (
                      <span className="price-num">
                        ৳ {book?.book_price}
                      </span>
                    )}
                  </>
                )}
              </div>

              {book?.reading_authorization == 3 && (
                <Link
                  onClick={() => addToCartHandler(book)}
                  className="btn btn-secondary box-btn btnhover btnhover2"
                >
                  <i className="flaticon-shopping-cart-1 m-r10"></i> Add to cart
                </Link>
              )}
              {book?.reading_authorization == 2 && (
                <Link
                  // onClick={()=>addToCartHandler(book)}
                  className="btn btn-secondary box-btn btnhover btnhover2"
                >
                  <i className="flaticon-shopping-cart-1 m-r10"></i> Buy plan
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookCard;
