import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
    return (
        <>
        <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-primary">
            <h3 className="display-1 fw-bold text-white">404 Not found</h3>
            <Link to={"/"} className='btn btn-success'>Back to home</Link>
        </div>
        </>
    );
};

export default Page404;