import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PdfAnnotator } from "react-pdf-highlighter";
import { useNavigate, useSearchParams } from "react-router-dom";
import ChapterDropdown from "../components/PdfFileViewer/ChapterDropdown";
import SettingsDropdown from "../components/PdfFileViewer/SettingsDropdown";
import "./viewer.scss";
import { SiteContext } from "../context/ContextProvider";

export const API_URL = "https://admin.readersfm.com";

export const useBookIdQuery = () => {
  const [searchParams] = useSearchParams();
  const book_id = searchParams.get("book_id");
  const chapter_id = searchParams.get("chapter_id");
  const ttn = searchParams.get("ttn");
  return { book_id, chapter_id, ttn };
};

function ViewPdfFile() {
  const { baseUrl } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const { book_id, chapter_id, ttn } = useBookIdQuery();
  const [pdf_url, setPdfUrl] = useState("");
  const [bookDetails, setBookDetails] = useState(null);
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);
  const [token, setToken] = useState("");
  const [isToken, setIsToken] = useState(false);
  const api = axios.create({
    baseURL: API_URL + "/api",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsToken(false);
      try {
        const response = await axios.post(`${baseUrl}/api/check-encrypt`, {
          encrypt_data: ttn,
        });
        const data = response.data;
        setToken(data.token);
        setIsToken(true);
      } catch (error) {
        console.log(error);
        setIsToken(false);
      }
    };

    fetchData();
  }, [ttn]);

  useEffect(() => {
    if (book_id) {
      if (isToken) {
        api.get("/book-by-chapter/" + book_id + "?type=ebook").then((v) => {
          const data = v.data?.data;
          const isChapter = data?.chapters && data?.chapters?.length > 0;
          setBookDetails(data);
          setHighlights([]);
          if (isChapter && chapter_id) {
            const findChapters = data?.chapters?.find(
              (v) => v.id == chapter_id
            );

            if (!findChapters) {
              navigate(`/book/pdf/show?book_id=${book_id}`);
            } else {
              const pdf_url = findChapters?.pdf_url;
              setPdfUrl(pdf_url);

              api
                .get(
                  `/chapter-highlight-note?book_id=${book_id}&chapter_id=${chapter_id}`
                )
                .then((v) => {
                  const webText =
                    v.data?.data?.chapter_highlight_or_note?.[0]?.web_text;
                  if (webText) {
                    try {
                      let data = JSON.parse(webText);
                      setHighlights(data);
                    } catch (e) {}
                  }
                  setLoading(false);
                });
            }
          } else {
            const pdfUrl = data?.book?.pdf_url;
            setPdfUrl(pdfUrl);
            api
              .get("/highlight-note?book_id=" + book_id)
              .then((v) => {
                const webText = v.data?.data?.highlight_or_note?.[0]?.web_text;

                if (webText) {
                  try {
                    let data = JSON.parse(webText);
                    setHighlights(data);
                  } catch (e) {}
                }
                setLoading(false);
              })
              .catch(() => {});
          }
        });
      }
    } else {
      // navigate(-1);
    }
  }, [book_id, chapter_id, token]);

  const updateHighlights = (h) => {
    if (chapter_id) {
      api
        .post("/chapter-save-highlight-note", {
          book_id,
          chapter_id,
          web_text: JSON.stringify(h || highlights),
        })
        .catch(() => {});
    } else {
      api
        .post("/save-highlight-note", {
          book_id,
          web_text: JSON.stringify(h || highlights),
        })
        .catch(() => {});
    }
  };

  const handleNewHighlight = (highlight) => {
    let newH = [...highlights, highlight];
    setHighlights(newH);
    updateHighlights(newH);
  };

  const [setting, setSetting] = useState({
    scrollMode: "vertical",
    pageMode: "single",
    zoom: "1.1",
  });

  const deleteHighlight = (id) => {
    const filter = highlights.filter((v) => v.id !== id);
    setHighlights(filter);
    updateHighlights(filter);
  };

  const updateHighlight = (highlightId, comment) => {
    let newHighlights = highlights.map((v) => {
      if (v.id === highlightId) {
        v.comment = comment;
      }
      return v;
    });

    setHighlights(newHighlights);
    updateHighlights(newHighlights);
  };

  const isChapter = bookDetails?.chapters && bookDetails?.chapters?.length > 0;

  const activeChapter = useMemo(() => {
    if (isChapter) {
      return bookDetails?.chapters?.find((v) => v.id == chapter_id);
    } else {
      return {};
    }
  }, [chapter_id, bookDetails, isChapter]);

  const handleChapterSelect = (v) => {
    navigate(
      `/app/book/pdf/show?book_id=${book_id}&chapter_id=${v.id}&ttn=${ttn}`
    );
  };

  if (loading) return null;

  return (
    <div className="view-audio-book-container">
      <div className="v_header-wrapper">
        <div className="container  v_header v_header-v2 d-flex justify-content-between">
          <div className="d-flex" style={{ gap: "30px" }}>
            <div onClick={() => navigate(-1)} className="app_view">
              <svg
                width={32}
                height={32}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9999 11H6.41394L11.7069 5.70703L10.2929 4.29303L2.58594 12L10.2929 19.707L11.7069 18.293L6.41394 13H20.9999V11Z"
                  fill="black"
                />
              </svg>
            </div>
            {isChapter && (
              <ChapterDropdown
                chapters={bookDetails?.chapters}
                activeChapter={chapter_id}
                onChapterSelect={handleChapterSelect}
              />
            )}
          </div>
          <div>
            {bookDetails?.book?.name || ""}{" "}
            {activeChapter?.name ? `- ${activeChapter?.name}` : ""}
          </div>

          <SettingsDropdown settingState={[setting, setSetting]} />
        </div>
      </div>

      <div className="  v_container-padding-v2  v_audio-player-container">
        <PdfAnnotator
          onNewHightlight={handleNewHighlight}
          // pdfSrc={`${API_URL}/get-pdf?url=${pdf_url}`}
          // pdfSrc={pdf_url}
          pdfSrc={`${baseUrl}/get-pdf?url=${pdf_url}`}
          highlights={highlights}
          onDeleteHighlight={deleteHighlight}
          onUpdateHighlight={updateHighlight}
          isDualPageArrow={setting.scrollMode === "horizontal"}
          pdfScaleValue={setting.zoom}
          onShareQuote={(text) => {
            console.log(text);
          }}
        />
      </div>
    </div>
  );
}

export default ViewPdfFile;
