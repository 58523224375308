import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../context/ContextProvider';
import { Link, useParams } from 'react-router-dom';

const OrderDetailsPlan = () => {
    const {loading, setLoading, baseUrl} = useContext(SiteContext);
    const [plans, setPlans] = useState(null);
    const peram = useParams();


    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
            const token = JSON.parse(localStorage.getItem("_token"));
      
            if (!token) {
              setLoading(false);
              return;
            }
      
            const response = await fetch(`${baseUrl}/api/order/details/${peram.id}?from=${peram.from}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
                },
            });
              
             
      
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
              setLoading(false);
            setPlans(data?.data?.plan_item);
          } catch (error) {
            console.error("Error fetching payment data:", error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, [setLoading, peram?.id, peram?.from, baseUrl]);




    return !loading ? (
        <>
            <div className='row'>
                <h4 className='text-center py-4'>Order details</h4>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Validity</th>
                        <th scope="col">Description</th>
                        <th scope="col">Paid</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                            <td>
                                {plans?.name}
                            </td> 
                            <td>
                            {
                                plans?.validity === "1" && "1 month"
                            }
                            {
                                plans?.validity === "2" && "3 months"
                            }
                            {
                                plans?.validity === "3" && "6 months"
                            }
                            {
                                plans?.validity === "4" && "1 year"
                            }
                            </td> 
                            <td>
                                {plans?.description}
                            </td> 
                            <td>৳
                                {plans?.price}
                            </td>
                        </tr>
                </tbody>
            </table>
        </>
     ) : (
      <div
        className=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
          background: "#fff",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
};

export default OrderDetailsPlan;