import React from "react";
import { Button, Modal } from "react-bootstrap";

const AccountDeleteModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4>Are you sure want to delete your account?</h4>
        <p>
          Deleting your account is a permanent action and cannot be undone. All
          your personal data, settings, and preferences will be permanently
          removed from our system. This includes any saved items, purchase
          history, and account information.
        </p>
        <p>
          Please confirm if you wish to proceed with deleting your account. If
          you have any questions or need assistance, feel free to contact our
          support team.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={`danger`} onClick={props.handleAccountDelete}>
          Delete Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountDeleteModal;
