import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  useNavigate,
} from "react-router-dom";
import ScrollToTop2 from "react-scroll-to-top";

//layouts
import Header from "./../layouts/Header";
import Footer from "./../layouts/Footer";
import ScrollToTop from "./../layouts/ScrollToTop";

//Pages
import Home from "./Home";
import AboutUs from "./AboutUs";
import MyProfile from "./MyProfile";
import Services from "./Services";
import Faq from "./Faq";
import HelpDesk from "./HelpDesk";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import AllBooks from "./AllBooks";
import CategoryBooks from "./CategoryBooks";
import Books from "./Books";
import Author from "./Author";
import Publisher from "./Publisher";
import SubCategoryBooks from "./SubCategoryBooks";
import ShopList from "./ShopList";
import BooksGridViewSidebar from "./BooksGridViewSidebar";
import BooksListViewSidebar from "./BooksListViewSidebar";
import ShopCart from "./ShopCart";
// import SavedBooks from "./SavedBooks";
import SavedBooks from "./SavedBooks";
import Login from "./Login";
import Registration from "./Registration";
import ShopCheckout from "./ShopCheckout";
import BookDetail from "./BookDetail";
import ContactUs from "./ContactUs";

import ErrorPage from "./ErrorPage";
import UnderConstruction from "./UnderConstruction";
import ComingSoon from "./ComingSoon";

//images
import logo from "./../assets/images/logo.png";
import Verification from "./Verification";

import { Worker } from "@react-pdf-viewer/core";
import { useContext } from "react";
import PdfJsView from "./test/PdfJsView";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import PaymentCancel from "./PaymentCancel";
import MyBooks from "./MyBooks";
import UserSidebar from "../layouts/UserSidebar";
import Transition from "./Transition";
import MyDashboard from "./MyDashboard";
import OrderDetails from "./OrderDetails";
import Search from "./Search";
import { SiteContext } from "../context/ContextProvider";
import Page404 from "./404";
import WalletHistory from "./WalletHistory";
import ViewAudioBook from "./ViewAudioBook";
import ViewPdfFile from "./ViewPdfFile";
import ViewEpubBook from "./ViewEpubBook";
import Plans from "./Plans";
import OrderDetailsPlan from "./OrderDetailsPlan";
import ReturnRefundPolicy from "./ReturnRefundPolicy";
import ResetPassword from "./ResetPassword";
import Narrator from "./Narrator";
import AppViewPdfFile from "./AppViewPdfFile";
import AppViewEpubBook from "./AppViewEpubBook";
import AppViewAudioBook from "./AppViewAudioBook";
import AppViewPdf from "./AppViewPdf";
import AudioPlayer from "./AudioBook";
import AppEpubBookView from "./AppEpubBookView";
import DeleteAccount from "./DeleteAccount";

function Index(props) {
  return (
    <BrowserRouter basename={`/`}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
        <Routes>
          <Route path="/error-404" element={<ErrorPage />} />
          <Route path="/under-construction" element={<UnderConstruction />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/book/pdf/show" element={<ViewPdfFile />} />
          <Route path="/book/epub/show" element={<ViewEpubBook />} />
          <Route path="/book/audio/show" element={<ViewAudioBook />} />

          <Route path="/appview/book/pdf/show" element={<AppViewPdf />} />
          <Route path="/app/book/pdf/show" element={<AppViewPdfFile />} />
          <Route path="/app/book/epub/show" element={<AppEpubBookView />} />
          <Route path="/app/book/audio/show" element={<AppViewAudioBook />} />

          <Route element={<MainLayout />}>
            <Route path="/" exact element={<Home />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route
              path="/search/books/:search_text"
              exact
              element={<Search />}
            />
            <Route path="/search/books/" exact element={<Search />} />
            <Route path="/services" exact element={<Services />} />
            <Route path="/faq" exact element={<Faq />} />
            <Route path="/help-desk" exact element={<HelpDesk />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              exact
              element={<TermsConditions />}
            />
            <Route
              path="/return-refund-policy"
              exact
              element={<ReturnRefundPolicy />}
            />
            <Route path="/all-books" exact element={<AllBooks />} />
            <Route path="/plans" exact element={<Plans />} />
            <Route
              path="/category-books/:id"
              exact
              element={<CategoryBooks />}
            />
            <Route path="/books/:id" exact element={<Books />} />{" "}
            {/*  Delete */}
            <Route path="/author-books/:id" exact element={<Author />} />
            <Route path="/narrator-books/:id" exact element={<Narrator />} />
            <Route path="/test/pdfjsview" exact element={<PdfJsView />} />
            <Route
              path="/subcategory-books/:id"
              exact
              element={<SubCategoryBooks />}
            />
            <Route path="/publisher/:id" exact element={<Publisher />} />
            <Route path="/books-list" exact element={<ShopList />} />
            <Route
              path="/books-grid-view-sidebar"
              exact
              element={<BooksGridViewSidebar />}
            />
            <Route
              path="/books-list-view-sidebar"
              exact
              element={<BooksListViewSidebar />}
            />
            <Route path="/shop-cart" exact element={<ShopCart />} />
            <Route path="/saved-books" exact element={<SavedBooks />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/reset-password" exact element={<ResetPassword />} />
            <Route path="/registration" exact element={<Registration />} />
            <Route
              path="/verification"
              exact
              element={<Verification />}
            ></Route>
            <Route path="/plan-purchase" exact element={<ShopCheckout />} />
            <Route path="/books-detail/:id" exact element={<BookDetail />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/success" exact element={<PaymentSuccess />} />
            <Route path="/failed" exact element={<PaymentFailed />} />
            <Route path="/cancel" exact element={<PaymentCancel />} />
          </Route>

          {/* User Dashboard route  */}
          <Route element={<UserLayout />}>
            <Route path="/mybooks" exact element={<MyBooks />} />
            <Route
              path="/delete-my-account"
              exact
              element={<DeleteAccount />}
            />
            <Route path="/my-dashboard" exact element={<MyDashboard />} />
            <Route path="/my-profile" exact element={<MyProfile />} />
            <Route path="/transition" exact element={<Transition />} />
            <Route path="/wallet-history" exact element={<WalletHistory />} />
            <Route path="/order-details/:id" exact element={<OrderDetails />} />
            <Route
              path="/order-details/:id"
              exact
              element={<OrderDetailsPlan />}
            />
          </Route>

          <Route element={<NotFound />}>
            <Route path="/*" exact element={<Page404 />} />
          </Route>
        </Routes>
      </Worker>
      <ScrollToTop />
      <ScrollToTop2
        className="styles_scroll-to-top__2A70v  fas fa-arrow-up scroltop"
        smooth
      />
    </BrowserRouter>
  );
}

function MainLayout() {
  return (
    <div className="page-wraper">
      <Header />
      <Outlet />
      <Footer footerChange="style-1" logoImage={logo} />
    </div>
  );
}
function UserLayout() {
  const { setLoading } = useContext(SiteContext);
  const navigate = useNavigate();
  if (!localStorage.getItem("_token")) {
    setLoading(false);
    navigate("/login", { state: { from: window.location.pathname } });
    return;
  }
  return (
    <div className="page-wraper">
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4 m-b30">
                  <UserSidebar />
                </div>
                <div className="col-xl-9 col-lg-8 m-b30">
                  <Outlet />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer footerChange="style-1" logoImage={logo} />
    </div>
  );
}

function NotFound() {
  return (
    <div className="page-wraper">
      <Outlet />
    </div>
  );
}
export default Index;
