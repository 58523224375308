import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../src/assets/vendor/bootstrap/scss/Header.scss';
import { SiteContext } from '../context/ContextProvider';
//images

function Header() {
  const [logoUrl, setLogoUrl] = useState(null);
  const [packageTitle, setPackageTitle] = useState(null);
  const [visibleCategories, setVisibleCategories] = useState(9);
  const [categoryData, setCategoryData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [mouseSide, setMouseSide] = useState('');
  const { isUser, setUser, cartItem, baseUrl, loading, setLoading } =
    useContext(SiteContext);

  const navigate = useNavigate();

  // Show all category
  const handleShowAllItems = () => {
    if (visibleCategories === 9) {
      setVisibleCategories(categoryData?.menus.length); // Set to the total number of categories
    } else {
      setVisibleCategories(9);
    }
  };
  // Function to handle category click
  /* const handleCategoryClick = (categoryId) => {
    setActiveCategory(activeCategory === categoryId ? null : categoryId);
  }; */

  const handleLogOut = () => {
    setLoading(true);
    fetch(`${baseUrl}/api/logout`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('_token'))}`,
      },
    })
      .then((res) => res)
      .then((data) => {
        localStorage.removeItem('_token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        setUser(null);
        navigate('/');
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${baseUrl}/api/home-header`)
      .then((response) => response.json())
      .then((data) => {
        setLogoUrl(data.data.cmsheader.header_logo);
        setPackageTitle(data.data.cmsheader.package_title);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [setLoading, baseUrl]);

  const footerMiddle = footerData?.cmsfooter?.footer_middle;
  const sectionOneData = footerMiddle?.filter((item) => item?.section == 1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/api/home-menu`
        );
        const data = await response.json();
        setCategoryData(data?.data);
        setFooterData(data?.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading, baseUrl]);

  const userName = JSON.parse(localStorage.getItem('name'));
  const userEmail = JSON.parse(localStorage.getItem('email'));

  /* for sticky header */
  const [headerFix, setheaderFix] = React.useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  /* for open menu Toggle btn  */
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const showSidebar = () => setSidebarOpen(!sidebarOpen);
  /*  Toggle btn End  */

  useEffect(() => {
    var mainMenu = document.getElementById('OpenMenu');
    if (mainMenu) {
      if (sidebarOpen) {
        mainMenu.classList.add('show');
      } else {
        mainMenu.classList.remove('show');
      }
    }
  });

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX, y: clientY });

    const windowCenter = window.innerWidth / 2;
    const side = clientX < windowCenter ? 'left' : 'right';
    setMouseSide(side);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Menu dropdown list
  const [active, setActive] = useState('Home');
  const handleMenuActive = (status) => {
    setActive(status);
    if (active === status) {
      setActive('');
    }
  };
  // Menu dropdown list End

  const [searchText, setSearchText] = useState(' ');

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (searchText.trim() !== '') {
      navigate(`/search/books/${searchText}`);
    }
  };

  return (
    <header className="site-header mo-left header style-1">
      <div className="header-info-bar">
        <div className="container">
          {/* <!-- Website Logo --> */}
          <div className="logo-header logo-dark">
            <Link to={'/'}>
              <img src={logoUrl} alt="" />
            </Link>
          </div>

          {/* <!-- EXTRA NAV --> */}
          <div className="extra-nav">
            <div className="extra-cell">
              <Link
                to="/plans"
                className="btn btn-primary btnhover btnhover2 ms-2"
              >
                {packageTitle}
              </Link>
              <ul className="navbar-nav header-right">
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="button"
                    type="button"
                    className="nav-link box cart-btn i-false"
                  >
                    <Link to="/shop-cart">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                      </svg>
                      <span className="badge">{cartItem?.length || 0}</span>
                    </Link>
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown
                  as="li"
                  className="nav-item dropdown profile-dropdown  ms-4"
                >
                  {isUser || userName || userEmail ? (
                    <Dropdown.Toggle as="div" className="nav-link i-false">
                      <img
                        src={
                          isUser?.profile?.profile_photo
                            ? isUser?.profile?.profile_photo
                            : '/avatar-.jpg'
                        }
                        alt="Profile"
                      />
                      <div className="profile-info">
                        <h6 className="title">{isUser?.name || userName}</h6>
                        <span>{isUser?.email || userEmail}</span>
                      </div>
                    </Dropdown.Toggle>
                  ) : (
                    <>
                      <Link
                        to={'/registration'}
                        className="btn btn-primary btnhover"
                      >
                        Registration
                      </Link>
                      <Link
                        to={'/login'}
                        className="ms-2 btn btn-primary btnhover"
                      >
                        Login
                      </Link>
                    </>
                  )}

                  <Dropdown.Menu className="dropdown-menu py-0 dropdown-menu-end">
                    <div className="dropdown-header">
                      <h6 className="m-0">{isUser?.name}</h6>
                      <span>{isUser?.email}</span>
                    </div>
                    <div className="dropdown-body">
                      <Link
                        to={'/my-dashboard'}
                        className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                          </svg>
                          <span className="ms-2">Dashboard</span>
                        </div>
                      </Link>

                      <Link
                        to={'/saved-books'}
                        className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                          </svg>
                          <span className="ms-2">Saved Books</span>
                        </div>
                      </Link>
                    </div>
                    <div className="dropdown-footer">
                      <button
                        onClick={handleLogOut}
                        className="btn btn-primary w-100 btnhover btn-sm"
                      >
                        Log Out
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </div>

          <div className="header-search-nav">
            <form onSubmit={handleSearchSubmit} className="header-item-search">
              <div className="input-group search-input">
                <input
                  type="text"
                  className="form-control"
                  aria-label="Text input with dropdown button"
                  placeholder="Search Books Here"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <button className="btn" type="submit">
                  <i className="flaticon-loupe"></i>
                </button>
              </div>
            </form>
          </div>

          <div />
        </div>
      </div>
      <div
        className={`sticky-header main-bar-wraper navbar-expand-lg ${
          headerFix ? 'is-fixed' : ''
        }`}
      >
        <div className="main-bar clearfix">
          <div className="container clearfix">
            {/* <!-- Website Logo --> */}
            <div className="logo-header logo-dark">
              <Link to={'/'}>
                <img src={logoUrl} alt="logo" />
              </Link>
            </div>

            {/* <!-- Nav Toggle Button --> */}
            <button
              className={`navbar-toggler collapsed navicon justify-content-end ${
                sidebarOpen ? 'open' : ''
              }`}
              onClick={showSidebar}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            {/* <!-- EXTRA NAV --> */}
            {/* <div className="extra-nav">
              <div className="extra-cell">
                <Link to={"/contact-us"} className="btn btn-primary btnhover">
                  Get In Touch
                </Link>
              </div>
            </div> */}

            {/* <!-- Main Nav --> */}
            <button
              className="position-absolute arrow-btn  translate-middle end-0 d-none d-lg-block"
              onClick={handleShowAllItems}
              title="More"
            >
              <img src="/arrow.png" alt="arrow" />
            </button>
            <div
              className={`header-nav navbar-collapse collapse justify-content-start ${
                sidebarOpen ? 'show' : ''
              }`}
              id="navbarNavDropdown"
            >
              <div className="logo-header logo-dark">
                <Link to={'/'}>
                  <img src={logoUrl} alt="" />
                </Link>
              </div>
              <form className="search-input">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    placeholder="Search Books Here"
                  />
                  <button className="btn" type="button">
                    <i className="flaticon-loupe"></i>
                  </button>
                </div>
              </form>
              <ul className="navbar-nav header-right d-md-none flex-row">
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="button"
                    type="button"
                    className="nav-link box cart-btn i-false"
                  >
                    <Link to="/shop-cart" className="relative sudo">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                      </svg>
                      <span className="badge position-absolute">
                        {cartItem?.length || 0}
                      </span>
                    </Link>
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown
                  as="li"
                  className="nav-item dropdown profile-dropdown  ms-4"
                >
                  {isUser || userName || userEmail ? (
                    <Dropdown.Toggle as="div" className="nav-link i-false">
                      <img
                        src={
                          isUser?.profile?.profile_photo
                            ? isUser?.profile?.profile_photo
                            : '/avatar-.jpg'
                        }
                        alt="Profile"
                      />
                      <div className="profile-info">
                        <h6 className="title">{isUser?.name || userName}</h6>
                        <span>{isUser?.email || userEmail}</span>
                      </div>
                    </Dropdown.Toggle>
                  ) : (
                    <>
                      <Link
                        to={'/registration'}
                        className="btn btn-primary btnhover"
                      >
                        Registration
                      </Link>
                      <Link
                        to={'/login'}
                        className="ms-2 btn btn-primary btnhover"
                      >
                        Login
                      </Link>
                    </>
                  )}

                  <Dropdown.Menu className="dropdown-menu py-0 dropdown-menu-end">
                    <div className="dropdown-header">
                      <h6 className="m-0">{isUser?.name}</h6>
                      <span>{isUser?.email}</span>
                    </div>
                    <div className="dropdown-body">
                      <Link
                        to={'/my-dashboard'}
                        className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                          </svg>
                          <span className="ms-2">Dashboard</span>
                        </div>
                      </Link>

                      <Link
                        to={'/saved-books'}
                        className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                          </svg>
                          <span className="ms-2">Saved Books</span>
                        </div>
                      </Link>
                    </div>
                    <div className="dropdown-footer">
                      <button
                        onClick={handleLogOut}
                        className="btn btn-primary w-100 btnhover btn-sm"
                      >
                        Log Out
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>

              <ul className="nav navbar-nav">
                <li>
                  <Link to="/all-books">বই গুলো</Link>
                </li>

                {categoryData?.menus
                  .slice(0, visibleCategories)
                  .map((category, index) => {
                    return (
                      <li
                        key={index}
                        className={`${
                          active === category.title && category.content
                            ? 'sub-menu-down open'
                            : category.content
                            ? 'sub-menu-down'
                            : ''
                        } `}
                      >
                        <Link onClick={() => handleMenuActive(category.name)}>
                          <span>{category.name}</span>
                        </Link>

                        <Collapse in={active === category.name ? true : false}>
                          <ul
                            className={`sub-menu ${
                              mouseSide === 'right' ? 'end-0' : 'start-0'
                            }`}
                          >
                            <div className="row">
                              {categoryData?.submenus
                                .filter(
                                  (subcategory) =>
                                    subcategory?.menu_id == category?.id
                                )
                                .map((subcategory, index) => {
                                  return (
                                    <div className="col-md-6" key={index}>
                                      <ul>
                                        <li>
                                          <Link
                                            to={
                                              subcategory?.action_link ===
                                              'category'
                                                ? `/category-books/${subcategory?.action_id}`
                                                : subcategory?.action_link ===
                                                  'subcategory'
                                                ? `subcategory`
                                                : subcategory?.action_link ===
                                                  'author'
                                                ? `/author-books/${subcategory?.action_id}`
                                                : subcategory?.action_link ===
                                                  'publisher'
                                                ? `/publisher/${subcategory?.action_id}`
                                                : subcategory?.action_link ===
                                                  'book'
                                                ? `book`
                                                : '#'
                                            }
                                          >
                                            {subcategory.name}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  );
                                })}
                            </div>
                          </ul>
                        </Collapse>
                      </li>
                    );
                  })}
              </ul>
              {/* <ul className="nav navbar-nav">
                <li>
                  <Link to="/all-books">বই গুলো</Link>
                </li>
                {categoryData?.menus?.map((category) => (
                  <li key={category?.id}>
                    <Link
                      to={`#`}
                      onClick={() => handleCategoryClick(category?.id)}
                      className={activeCategory == category?.id ? "active" : ""}
                    >
                      {category?.name}
                    </Link>
                    {activeCategory === category?.id && (
                      <ul className="sub-menu">
                        {categoryData?.submenus
                          .filter(
                            (subcategory) =>
                              subcategory?.menu_id == category?.id
                          )
                          .map((subcategory) => (
                            
                                  <li key={subcategory?.id}>
                                    <Link

                                        to={
                                            subcategory?.action_link === "category"
                                            ? `/category-books/${subcategory?.action_id}`
                                            : subcategory?.action_link === "subcategory"
                                            ? `subcategory`
                                            : subcategory?.action_link === "author"
                                            ? `/author-books/${subcategory?.action_id}`
                                            : subcategory?.action_link === "publisher"
                                            ? `/publisher/${subcategory?.action_id}`
                                            : subcategory?.action_link === "book"
                                            ? `book`
                                            : "#"
                                        }
                                      
                                      // onClick={()=>subMenuClickHandler(subcategory?.action_link)}
                                      
                                    >
                                      {subcategory?.name}
                                    </Link>
                                  </li>
                            
                          ))}
                      </ul>
                      
                    )}
                  </li>
                ))}
                
              </ul> */}

              <div className="dz-social-icon">
                <ul>
                  {sectionOneData?.map((item) => (
                    <li key={item?.id}>
                      <a
                        href={item?.link}
                        target="_blank"
                        rel="noreferrer"
                        title={item?.name}
                      >
                        <img
                          src={item?.icon}
                          alt={item?.name}
                          height={30}
                          width={30}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
