import React, { useContext, useEffect, useState } from "react";
import { SiteContext } from "../context/ContextProvider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PageTitle from "../layouts/PageTitle";

const Plans = () => {
  const { isUser, loading, setLoading, baseUrl } = useContext(SiteContext);
  const [plans, setPlans] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch Plans
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/plans`);
        const data = response.data;

        setPlans(data?.data?.plans);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPlans();
  }, [setLoading, baseUrl]);

  useEffect(() => {
    document.title = "ReadersFM | Plan pricing";
  }, []);

  const goToBuy = (plan) => {
    if (!isUser) {
      setLoading(false);
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }
    navigate("/plan-purchase", { state: { plan: plan } });
  };

  return !loading ? (
    <>
      <div className="page-content">
        <PageTitle childPage="Pricing Plans" parentPage="Home" />
        <section className="content-inner-1 bg-light">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="title">All plans</h4>
            </div>

            <div className="filter-area m-b30">
              <div className="grid-area">
                <div className="shop-tab">
                  <ul
                    className="nav text-center product-filter justify-content-end"
                    role="tablist"
                  ></ul>
                </div>
              </div>
            </div>
            <div className="row pricingtable-wraper">
              {plans?.map((data, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="pricingtable-wrapper style-1 m-b30">
                    <div className="pricingtable-inner">
                      <div className="pricingtable-title">
                        <h3 className="title">{data.name}</h3>
                      </div>
                      <div className="pricingtable-price">
                        <h2 className="pricingtable-bx">
                          ৳{data.price}
                          <small className="pricingtable-type">
                            /{data?.validity === "1" && "1 month"}
                            {data?.validity === "2" && "3 months"}
                            {data?.validity === "3" && "6 months"}
                            {data?.validity === "4" && "1 year"}
                          </small>
                        </h2>
                      </div>
                      <p className="text">{data.description}</p>
                      {/* <ul className="pricingtable-features">
                                                <li>Graphic Design </li>
                                                <li>Web Design</li>
                                                <li>UI/UX</li>
                                                <li>HTML/CSS</li>
                                                <li>SEO Marketing</li>
                                                <li>Business Analysis</li>
                                            </ul> */}
                      <div className="pricingtable-footer">
                        {isUser?.plan_id === data?.id ? (
                          <span className="text-success">
                            Already purchesed
                          </span>
                        ) : (
                          <button
                            onClick={() => goToBuy(data)}
                            className="btn btn-primary btnhover3"
                          >
                            Buy Now <i className="fa fa-angle-right m-l10"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Plans;
