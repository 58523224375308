import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

//Components
import PageTitle from "./../layouts/PageTitle";
import TestimonialSlider from "./../components/Home/TestimonialSlider";
import ClientsSlider from "./../components/Home/ClientsSlider";
import NewsLetter from "../components/NewsLetter";

//element
import CounterSection from "./../elements/CounterSection";

//Imgaes
import about1 from "./../assets/images/about/about1.jpg";
import about2 from "./../assets/images/about/about1.png";

const missionBlog = [
  { iconClass: "flaticon-open-book-1", title: "Accessibility" },
  { iconClass: "flaticon-exclusive", title: "Quality" },
  { iconClass: "flaticon-store", title: "Lifelong Learning" },
];

function AboutUs() {
  useEffect(() => {
    document.title = "ReadersFM | About Us";
  }, []);

  return (
    <>
      <div className="page-content bg-white">
        {/* <PageTitle parentPage="Home" childPage="About us" /> */}
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-6 m-b30">
                <div className="row sp10 about-thumb">
                  <div className="col-sm-6 aos-item">
                    <div className="split-box">
                      <div>
                        <img className="m-b30" src={about1} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="split-box ">
                      <div>
                        <img className="m-b20 aos-item" src={about2} alt="" />
                      </div>
                    </div>
                    <div className="exp-bx aos-item">
                      <div className="exp-head">
                        {/* <div className="counter-num">
                          <h2>
                            <span className="counter">
                              {" "}
                              <CountUp end={50} />
                            </span>
                            <small>+</small>
                          </h2>
                        </div>
                        <h6 className="title">Years of Experience</h6> */}
                      </div>
                      <div className="exp-info">
                        <ul className="list-check primary">
                          <li>Comics & Graphics</li>
                          <li>Biography</li>
                          <li>Literary Collections</li>
                          <li>Accademic</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <div className="section-head style-1">
                    <h2 className="title">
                      Welcome to ReadersFM eBook and Audiobook Platform – Your Learning Companion!
                    </h2>
                    <p>
                    At ReadersFM, we are passionate about learning and dedicated to providing you with an unparalleled collection of e-books, audiobooks, journals, and an array of digital resources. Our platform, available at ReadersFM, is designed to be your one-stop destination for enriching your mind, expanding your horizons, and embarking on a journey of self-study and research.
                    </p>
                  </div>
                  <Link
                    to={"/contact-us"}
                    className="btn btn-primary btnhover shadow-primary"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-1 bg-light">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">Our Mission</h2>
              <p>
              Our mission at ReadersFM is to break down the barriers to knowledge and empower individuals to achieve their learning goals through convenient access to a vast treasure trove of digital resources. With ReadersFM we aim to revolutionize the way you learn and engage with information.
              </p>
            </div>
            <div className="row">
              {missionBlog.map((data, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className="icon-bx-wraper style-3 m-b30">
                    <div className="icon-lg m-b20">
                      <i className={`icon-cell ${data.iconClass}`}></i>
                    </div>
                    <div className="icon-content">
                      <h4 className="title">{data.title}</h4>
                      <p>
                      We believe that education should be accessible to all. ReadersFM is committed to providing an inclusive platform where anyone can explore and learn, regardless of their background or location.
                      </p>
                      <Link to={"/about-us"}>
                        Learn More <i className="fa-solid fa-angles-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <section className="content-inner-1 testimonial-wrapper">
          <TestimonialSlider />
        </section> */}
        <section className="content-inner bg-light">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        <div className="py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        {/* <NewsLetter /> */}
      </div>
    </>
  );
}
export default AboutUs;
