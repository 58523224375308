import useFullscreen from "ahooks/lib/useFullscreen";
import WindowscreenIcon from "../assets/icons/WindowscreenIcon";
import FullscreenIcon from "../assets/icons/FullscreenIcon";

function FullscreenButton() {
  const [isFullScreen, { toggleFullscreen }] = useFullscreen(document.body);

  return (
    <div onClick={toggleFullscreen}>
      {isFullScreen ? (
        <WindowscreenIcon fill={"#333"} />
      ) : (
        <FullscreenIcon fill={"#333"} />
      )}
    </div>
  );
}

export default FullscreenButton;
