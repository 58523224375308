import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import { SiteContext } from "../context/ContextProvider";
import CounterSection from "../elements/CounterSection";

function SubCategoryBooks() {
  useEffect(() => {
    document.title = "ReadersFM | Sub Category Books";
  }, []);
  const  {isUser, setUser, setLoading, baseUrl} = useContext(SiteContext);
  const [subcategoryBooks, setSubcategoryBooks] = useState(null);

  // Get ID
  const urlString = window?.location?.pathname;
  const id = urlString?.split("/")[2];

  // pagination start 
  const [currentPage, setCurrentPage] = useState(`${baseUrl}/api/sub-category/${id}`);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${currentPage}`
        );
        const data = await response.json();
        setSubcategoryBooks(data?.data?.books);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, setLoading]);

  const totalPages = subcategoryBooks?.last_page;

  const handlePageClick = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    
  };

  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      
      <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
        <Link to="#" style={subcategoryBooks?.current_page === i ? { backgroundColor: '#274898', color: '#fff',pointerEvents: 'none' }:null} className={`page-link`} onClick={() => handlePageClick(`${baseUrl}/api/books?page=`+i)}>
          {i}
        </Link>
      </li>
    );
  }

    // pagination end 



  useEffect(() => {
    fetch(`${baseUrl}/api/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data?.user);
      });
  }, [setUser, baseUrl]);

  const navigate = useNavigate();
  const handleHeart = () => {
    if (!isUser) {
      navigate("/login");
    } else {
      
    }
  };

  return (
    <>
      <div className="page-content bg-grey">
        <section className="content-inner-1 border-bottom">
          <div className="container">
            <div className="">
              <h4 className="title pt-2">Sub Category Books</h4>
            </div>

            <div className="row book-grid-row">
              {
                subcategoryBooks?.data?.length === 0 && (
                <span className="text-danger">No data found!</span>
              )
              }
              {subcategoryBooks?.data?.map((book, i) => (
                <div className="col-book style-1" key={i}>
                  <div className="dz-shop-card style-1">
                    <div className="dz-media">
                      <img
                        src={book?.book_details?.book_image}
                        style={{ height: "300px" }}
                        alt="book"
                      />
                    </div>
                    <div className="bookmark-btn style-2">
                      <button
                        className="p-0 m-0 border-0"
                        onClick={handleHeart}
                      >
                        <input
                          className="form-check-input"
                          disabled={!isUser}
                          type="checkbox"
                          id="flexCheckDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault1"
                        >
                          <i className="flaticon-heart"></i>
                        </label>
                      </button>
                    </div>
                    <div className="dz-content">
                      <h5 className="title">
                        <Link to={`/books-detail/${book?.book_details?.id}`}>
                          {book?.book_details?.book_name}
                        </Link>
                      </h5>
                      <ul className={`dz-rating`}>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                      </ul>
                      <div className="book-footer">
                        <div className="price">
                          {book?.book_details?.reading_authorization == 1 ? (
                            <span className="price-num">Free</span>
                          ) : book?.book_details?.reading_authorization == 2 ? (
                            <span className="price-num">Subscription</span>
                          ) : (
                            <>
                              {book?.book_details?.book_discount ? (
                                <span className="price-num">
                                  ৳
                                  {book?.book_price *
                                    (1 -
                                      book?.book_details?.book_discount / 100)}
                                </span>
                              ) : (
                                <span className="price-num">
                                  ৳{book?.book_details?.book_price}
                                </span>
                              )}
                              {book?.book_details?.book_discount && (
                                <del>৳{book?.book_details?.book_discount}</del>
                              )}
                            </>
                          )}
                        </div>

                        {book?.book_details?.reading_authorization != 1 && (
                          <Link
                            to={"/shop-cart"}
                            className="btn btn-secondary box-btn btnhover btnhover2"
                          >
                            <i className="flaticon-shopping-cart-1 m-r10"></i>{" "}
                            Add to cart
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row page mt-0">
              <div className="col-md-6">
                {/* <p className="page-text">Showing 12 from 50 data</p> */}
              </div>
              <div className="col-md-6">
                <nav aria-label="Blog Pagination">
                  <ul className="pagination style-1 p-t20">
                    {/* pagination start   */}
                    <li className="page-item">
                      {subcategoryBooks?.prev_page_url && (
                        <Link
                          to={"#"}
                          className="page-link prev"
                          onClick={() => handlePageClick(subcategoryBooks?.prev_page_url)}
                        >
                          Prev
                        </Link>
                      )}

                    </li>

                    {paginationItems}

                   

                    <li className="page-item">
                      {subcategoryBooks?.next_page_url && (
                        <Link to={"#"} className="page-link next" onClick={() => handlePageClick(subcategoryBooks?.next_page_url)}>
                        Next
                      </Link>
                      )}
                      
                    </li>
                    {/* pagination end   */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <section className="content-inner">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        {/* <NewsLetter /> */}
      </div>
    </>
  );
}
export default SubCategoryBooks;