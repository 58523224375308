import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//Components
import { SiteContext } from "../context/ContextProvider";

function Registration() {
  const { baseUrl, loading, setLoading } = useContext(SiteContext);
  useEffect(() => {
    document.title = "ReadersFM | Registration";
  }, []);

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleRegistration = (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.target;
    const name = form.name.value;
    const email = form.email.value;
    const password = form.password.value;
    const password_confirmation = form.password_confirmation.value;

    const user = { name, email, password, password_confirmation };

    setError("");

    fetch(`${baseUrl}/api/register`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.code === 302) {
          setError(data?.message);
          setLoading(false);
          return;
        }

        if (data?.code === 200) {
          navigate("/verification", {
            state: {
              email: `${data?.data?.email_phone}`,
              name: `${name}`,
              password: `${password}`,
              message: `${data?.message}`,
            },
          });
          setLoading(false);
        }

        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  return !loading ? (
    <>
      <div className="page-content">
        {/* <PageTitle parentPage="Shop" childPage="Registration" /> */}
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <form onSubmit={handleRegistration}>
                    <h4 className="text-secondary">Registration</h4>
                    <p className="font-weight-600">
                      If you don't have an account with us, please Registration.
                    </p>
                    {/* <p style={{ color: "red" }}>{error}</p> */}
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}

                    <div className="mb-4">
                      <label className="label-title">Name *</label>
                      <input
                        name="name"
                        required
                        className="form-control"
                        placeholder="Your name"
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Email address *</label>
                      <input
                        name="email"
                        required
                        className="form-control"
                        placeholder="Your email address"
                        type="email"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Password *</label>
                      <input
                        name="password"
                        required
                        className="form-control "
                        placeholder="Type password"
                        type="password"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Confirm Password *</label>
                      <input
                        name="password_confirmation"
                        required
                        className="form-control "
                        placeholder="Type password"
                        type="password"
                      />
                    </div>
                    <div className="mb-5">
                      <small>
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account, and for other purposes described in our{" "}
                        <Link to={"/privacy-policy"}>privacy policy</Link>.
                      </small>
                    </div>
                    <div className="text-left">
                      <button
                        type="submit"
                        className="btn btn-primary btnhover w-100 me-2"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <div
      className=""
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: "#fff",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default Registration;
