import React, { useEffect, useState } from "react";
import { API_URL } from "../../pages/constant";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import BookCard from "../../layouts/BookCard";

function BookSaleSlider() {
  const [trendingBooks, setTrendingBooks] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/home-trending`);
        const data = await response.json();
        setTrendingBooks(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);
  return (
    <>
      {trendingBooks?.trending_books?.length !== 0 && (
        <>
          <div className="section-head book-align">
            <h2 className="title mb-0">{trendingBooks?.name}</h2>
            <div className="pagination-align style-1">
              <div className="swiper-button-prev" ref={navigationPrevRef}>
                <i className="fa-solid fa-angle-left"></i>
              </div>
              <div className="swiper-pagination-two" ref={paginationRef}></div>
              <div className="swiper-button-next" ref={navigationNextRef}>
                <i className="fa-solid fa-angle-right"></i>
              </div>
            </div>
          </div>
          <Swiper
            className="swiper-container books-wrapper-3 swiper-four"
            speed={1500}
            parallax={true}
            slidesPerView={5}
            spaceBetween={30}
            loop={true}
            pagination={{
              el: ".swiper-pagination-two",
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            modules={[Navigation, Pagination]}
            breakpoints={{
              1200: {
                slidesPerView: 5,
              },
              1191: {
                slidesPerView: 4,
              },
              767: {
                slidesPerView: 3,
              },
              591: {
                slidesPerView: 2,
                centeredSlides: true,
              },
              320: {
                slidesPerView: 2,
                spaceBetween: 15,
                // centeredSlides: true,
              },
            }}
          >
            {trendingBooks?.trending_books?.map((trendingBook, ind) => (
              <SwiperSlide key={ind}>
                <BookCard book={trendingBook} height={"280px"} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </>
  );
}
export default BookSaleSlider;
