import React from "react";

function WindowscreenIcon(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.83333 14H3.16667C2.85725 14 2.5605 14.1405 2.34171 14.3905C2.12292 14.6406 2 14.9797 2 15.3333C2 15.687 2.12292 16.0261 2.34171 16.2761C2.5605 16.5262 2.85725 16.6667 3.16667 16.6667H6.66667V20.6667C6.66667 21.0203 6.78958 21.3594 7.00838 21.6095C7.22717 21.8595 7.52391 22 7.83333 22C8.14275 22 8.4395 21.8595 8.65829 21.6095C8.87708 21.3594 9 21.0203 9 20.6667V15.3333C9.00009 15.1582 8.96997 14.9848 8.91137 14.823C8.85277 14.6612 8.76684 14.5141 8.65849 14.3903C8.55013 14.2665 8.42149 14.1683 8.2799 14.1013C8.13831 14.0343 7.98657 13.9999 7.83333 14Z"
        // fill="black"
      />
      <path
        d="M20.6667 14H15.3333C15.1582 13.9999 14.9848 14.0343 14.823 14.1013C14.6612 14.1683 14.5141 14.2665 14.3903 14.3903C14.2665 14.5141 14.1683 14.6612 14.1013 14.823C14.0343 14.9848 13.9999 15.1582 14 15.3333V20.6667C14 21.0203 14.1405 21.3594 14.3905 21.6095C14.6406 21.8595 14.9797 22 15.3333 22C15.687 22 16.0261 21.8595 16.2761 21.6095C16.5262 21.3594 16.6667 21.0203 16.6667 20.6667V16.6667H20.6667C21.0203 16.6667 21.3594 16.5262 21.6095 16.2761C21.8595 16.0261 22 15.687 22 15.3333C22 14.9797 21.8595 14.6406 21.6095 14.3905C21.3594 14.1405 21.0203 14 20.6667 14Z"
        // fill="black"
      />
      <path
        d="M15.3333 9H20.6667C21.0203 9 21.3594 8.87708 21.6095 8.65829C21.8595 8.4395 22 8.14275 22 7.83333C22 7.52391 21.8595 7.22717 21.6095 7.00838C21.3594 6.78958 21.0203 6.66667 20.6667 6.66667H16.6667V3.16667C16.6667 2.85725 16.5262 2.5605 16.2761 2.34171C16.0261 2.12292 15.687 2 15.3333 2C14.9797 2 14.6406 2.12292 14.3905 2.34171C14.1405 2.5605 14 2.85725 14 3.16667V7.83333C13.9999 7.98657 14.0343 8.13831 14.1013 8.2799C14.1683 8.42149 14.2665 8.55013 14.3903 8.65849C14.5141 8.76684 14.6612 8.85277 14.823 8.91137C14.9848 8.96997 15.1582 9.00009 15.3333 9Z"
        // fill="black"
      />
      <path
        d="M7.83333 2C7.6801 1.9999 7.52835 2.03432 7.38677 2.10129C7.24518 2.16826 7.11653 2.26647 7.00818 2.3903C6.89983 2.51413 6.8139 2.66116 6.7553 2.82297C6.6967 2.98478 6.66658 3.15821 6.66667 3.33333V7.33333H3.16667C2.85725 7.33333 2.5605 7.47381 2.34171 7.72386C2.12292 7.97391 2 8.31304 2 8.66667C2 9.02029 2.12292 9.35943 2.34171 9.60947C2.5605 9.85952 2.85725 10 3.16667 10H7.83333C7.98657 10.0001 8.13831 9.96568 8.2799 9.89871C8.42149 9.83174 8.55013 9.73353 8.65849 9.6097C8.76684 9.48586 8.85277 9.33884 8.91137 9.17703C8.96997 9.01522 9.00009 8.84179 9 8.66667V3.33333C9.00009 3.15821 8.96997 2.98478 8.91137 2.82297C8.85277 2.66116 8.76684 2.51413 8.65849 2.3903C8.55013 2.26647 8.42149 2.16826 8.2799 2.10129C8.13831 2.03432 7.98657 1.9999 7.83333 2Z"
        // fill="black"
      />
    </svg>
  );
}

export default WindowscreenIcon;
