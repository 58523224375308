import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../context/ContextProvider';
import { useNavigate, useParams } from 'react-router-dom';


const OrderDetails = () => {
    const {loading, setLoading, baseUrl} = useContext(SiteContext);
    const [orders, setOrders] = useState(null);
    const peram = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
            const token = JSON.parse(localStorage.getItem("_token"));
      
            if (!token) {
              setLoading(false);
              return;
            }
      
            const response = await fetch(`${baseUrl}/api/order/details/${peram?.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
                },
            });
              
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
              setLoading(false);
            setOrders(data?.data?.order_items);
          } catch (error) {
            console.error("Error fetching payment data:", error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, [setLoading, peram?.id, baseUrl]);


      // Initialize total variables
        const [totalPrice, setTotalPrice] = useState(0);
        const [discountTotal, setDiscountTotal] = useState(0);
        const [couponTotal, setCouponTotal] = useState(0);
        const [payableTotal, setPayableTotal] = useState(0);

        useEffect(() => {
            // Reset totals
            setTotalPrice(0);
            setDiscountTotal(0);
            setCouponTotal(0);
            setPayableTotal(0);

            // Calculate totals
            if (orders) {
            orders.forEach((order) => {
                // Parse the book properties to floats
                const price = parseFloat(order.total);
                const discount = parseFloat(order.discount);
                const coupon = parseFloat(order.coupon);
                const payable = parseFloat(order.payable);

                // Accumulate totals
                setTotalPrice((prevTotal) => prevTotal + price);
                setDiscountTotal((prevDiscountTotal) => prevDiscountTotal + discount);
                setCouponTotal((prevCouponTotal) => prevCouponTotal + coupon);
                setPayableTotal((prevPayableTotal) => prevPayableTotal + payable);
            });
            }
        }, [orders]);
    
    
    const handleReadBook = (book) => {
    const fileType = book?.book_file_type;
        const book_id = book?.id;
        if (fileType === "pdf") {
            navigate(`/book/pdf/show?book_id=${book_id}`);
        } else if (fileType === "epub") {
            navigate(`/book/epub/show?book_id=${book_id}`);
        } else {
            navigate(`/book/audio/show?book_id=${book_id}`);
        }
  };


    return !loading ? (
        <>
            <div className='row'>
                <h4 className='text-center py-4'>Order details</h4>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Coupon</th>
                        <th scope="col">Paid</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    orders?.map((item, i) => (
                        <tr key={i}>
                            <td className='d-flex'>
                                <img style={{"width":"60px"}} src={item?.book?.book_image} alt="Book Cover" />
                                <span className=' ms-2'>{item?.book?.book_name}</span>
                            </td> 
                            <td>৳
                                {item?.total}
                            </td>
                            <td>৳
                                {item?.discount}
                            </td> 
                            <td>৳
                                {item?.coupon}
                            </td> 
                            <td>৳
                                {item?.payable}
                            </td>
                            <td>
                                
                                <button
                                  onClick={()=>handleReadBook(item?.book)}
                                  type="submit"
                                  className="btn btn-primary btnhover btnhover2 ms-2">
                                    <span>
                                        {item?.book?.book_type === 1 ? "Read Now":"Listen Now"}
                                    </span>
                                </button>
                              
                            </td>
                        </tr>
                        )
                    )
                }
                    <tr className='bg-dark text-light'>
                    
                        <td>Total</td>
                        <td>৳{totalPrice.toFixed(2)}</td>
                        <td>৳{discountTotal.toFixed(2)}</td>
                        <td>৳{couponTotal.toFixed(2)}</td>
                        <td>৳{payableTotal.toFixed(2)}</td>
                        <td></td>
                </tr>
                </tbody>
            </table>
        </>
     ) : (
      <div
        className=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
          background: "#fff",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
};

export default OrderDetails;