import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import CounterSection from "../elements/CounterSection";

const lableBlogData = [
  { name: "Architecture" },
  { name: "Art" },
  { name: "Action" },
  { name: "Biography & Autobiography" },
  { name: "Body, Mind & Spirit" },
  { name: "Business & Economics" },
  { name: "Children Fiction" },
  { name: "Children Non-Fiction" },
  { name: "Comics & Graphic Novels" },
  { name: "Cooking" },
  { name: "Crafts & Hobbies" },
  { name: "Design" },
  { name: "Drama" },
  { name: "Education" },
  { name: "Family & Relationships" },
  { name: "Fiction" },
  { name: "Foreign Language Study" },
  { name: "Games" },
  { name: "Gardening" },
  { name: "Health & Fitness" },
  { name: "History" },
  { name: "House & Home" },
  { name: "Humor" },
  { name: "Literary Collections" },
  { name: "Mathematics" },
];

function AuthorBooks() {
  useEffect(() => {
    document.title = "ReadersFM | Author Books";
  }, []);

  const [authorDetails, setAuthorDetails] = useState(null);
  const [authorBooks, setAuthorBooks] = useState(null);
  const [isUser, setUser, baseUrl] = useState(null);

  // Get ID
  const urlString = window?.location?.pathname;
  const id = urlString?.split("/")[2];

  useEffect(() => {
    // Fetch Book Details
    const AuthorData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/author/${id}?user_id=${isUser?.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("_token")
              )}`,
            },
          }
        );
        const data = await response.json();
        setAuthorDetails(data?.data?.author_details);
        setAuthorBooks(data?.data?.books);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    AuthorData();
  }, [id, isUser?.id, baseUrl]);

  useEffect(() => {
    fetch(`${baseUrl}/api/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data?.user);
      });
  }, [baseUrl, setUser]);

  const navigate = useNavigate();
  const handleHeart = () => {
    if (!isUser) {
      navigate("/login");
    } else {
    }
  };

  return (
    <>
      <div className="page-content bg-grey">
        <section className="content-inner-1 border-bottom">
          <div className="container">
            <div className="">
              <h4 className="title">Author Detail</h4>
              <div>
                <img
                  src={authorDetails?.image_url}
                  style={{ height: 100 }}
                  alt=""
                />
                <h5>{authorDetails?.name}</h5>
              </div>
              <h4 className="title pt-2">Author Books</h4>
            </div>

            <div className="row book-grid-row">
              {authorBooks?.map((book, i) => (
                <div className="col-book style-1" key={i}>
                  <div className="dz-shop-card style-1">
                    <div className="dz-media">
                      <img
                        src={book?.book_details?.book_image}
                        style={{ height: "300px" }}
                        alt="book"
                      />
                    </div>
                    <div className="bookmark-btn style-2">
                      <button
                        className="p-0 m-0 border-0"
                        onClick={handleHeart}
                      >
                        <input
                          className="form-check-input"
                          disabled={!isUser}
                          type="checkbox"
                          id="flexCheckDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault1"
                        >
                          <i className="flaticon-heart"></i>
                        </label>
                      </button>
                    </div>
                    <div className="dz-content">
                      <h5 className="title">
                        <Link to={`/books-detail/${book?.book_details?.id}`}>
                          {book?.book_details?.book_name}
                        </Link>
                      </h5>
                      <ul className={`dz-rating`}>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                        <li>
                          <i className="flaticon-star text-yellow"></i>
                        </li>
                      </ul>
                      <div className="book-footer">
                        <div className="price">
                          {book?.book_details?.reading_authorization == 1 ? (
                            <span className="price-num">Free</span>
                          ) : book?.book_details?.reading_authorization == 2 ? (
                            <span className="price-num">Subscription</span>
                          ) : (
                            <>
                              {book?.book_details?.book_discount ? (
                                <span className="price-num">
                                  ৳
                                  {book?.book_details?.book_price *
                                    (1 -
                                      book?.book_details?.book_discount / 100)}
                                </span>
                              ) : (
                                <span className="price-num">
                                  ৳{book?.book_details?.book_price}
                                </span>
                              )}
                              {book?.book_details?.book_discount && (
                                <del>৳{book?.book_details?.book_discount}</del>
                              )}
                            </>
                          )}
                        </div>

                        {book?.book_details?.reading_authorization != 1 && (
                          <Link
                            to={"/shop-cart"}
                            className="btn btn-secondary box-btn btnhover btnhover2"
                          >
                            <i className="flaticon-shopping-cart-1 m-r10"></i>{" "}
                            Add to cart
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row page mt-0">
              <div className="col-md-6">
                {/* <p className="page-text">Showing 12 from 50 data</p> */}
              </div>
              <div className="col-md-6">
                <nav aria-label="Blog Pagination">
                  <ul className="pagination style-1 p-t20">
                    <li className="page-item">
                      <Link to={"#"} className="page-link prev">
                        Prev
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link active">
                        1
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        2
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        3
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link next">
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <section className="content-inner">
          <div className="container">
            <div className="row sp15">
              <CounterSection />
            </div>
          </div>
        </section>
        {/* <NewsLetter /> */}
      </div>
    </>
  );
}
export default AuthorBooks;
