import React, { useContext, useEffect, useMemo, useState } from "react";
import "./viewer.scss";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { useNavigate } from "react-router";
import ChapterDropdown from "../components/PdfFileViewer/ChapterDropdown";
import axios from "axios";
import { SiteContext } from "../context/ContextProvider";
import { API_URL } from "./constant";
import { useSearchParams } from "react-router-dom";
export const useBookIdQuery = () => {
  const [searchParams] = useSearchParams();
  const book_id = searchParams.get("book_id");
  const chapter_id = searchParams.get("chapter_id");
  const ttn = searchParams.get("ttn");
  return { book_id, chapter_id, ttn };
};

function AppViewAudioBook() {
  const navigate = useNavigate();
  const { baseUrl } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const { book_id, chapter_id, ttn } = useBookIdQuery();
  const [audio_url, setAudioUrl] = useState("");
  const [bookDetails, setBookDetails] = useState(null);
  const [token, setToken] = useState("");
  const [isToken, setIsToken] = useState(false);
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsToken(false);
      try {
        const response = await axios.post(`${baseUrl}/api/check-encrypt`, {
          encrypt_data: ttn,
        });
        const data = response.data;
        setToken(data.token);
        setIsToken(true);
      } catch (error) {
        console.log(error);
        setIsToken(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (book_id) {
      if (isToken) {
        api.get("/book-by-chapter/" + book_id + "?type=audio").then((v) => {
          const data = v.data?.data;
          const isChapter = data?.chapters && data?.chapters?.length > 0;
          setBookDetails(data);

          if (isChapter && chapter_id) {
            const findChapters = data?.chapters?.find(
              (v) => v.id == chapter_id
            );

            if (!findChapters) {
              navigate(`/book/audio/show?book_id=${book_id}`);
            } else {
              const audio_url = findChapters?.audio_url;
              setAudioUrl(audio_url);
            }
          } else {
            const book_audio = data?.book?.book_audio;
            setAudioUrl(book_audio);
          }
          setLoading(false);
        });
      }
    } else {
      navigate(-1);
    }
  }, [book_id, chapter_id, isToken]);

  const isChapter = bookDetails?.chapters && bookDetails?.chapters?.length > 0;

  const activeChapter = useMemo(() => {
    if (isChapter) {
      return bookDetails?.chapters?.find((v) => v.id === chapter_id);
    } else {
      return {};
    }
  }, [chapter_id, bookDetails, isChapter]);

  const handleChapterSelect = (v) => {
    navigate(`/book/audio/show?book_id=${book_id}&chapter_id=${v.id}`);
  };

  if (loading) return null;

  return (
    <div className="view-audio-book-container app_view ">
      <div className="v_header-wrapper">
        <div className="container clearfix v_header">
          <div className="d-flex" style={{ gap: "30px" }}>
            <div
              onClick={() => navigate(`/books-detail/${book_id}`)}
              className=""
            >
              <svg
                width={32}
                height={32}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9999 11H6.41394L11.7069 5.70703L10.2929 4.29303L2.58594 12L10.2929 19.707L11.7069 18.293L6.41394 13H20.9999V11Z"
                  fill="black"
                />
              </svg>
            </div>
            {isChapter && (
              <ChapterDropdown
                chapters={bookDetails?.chapters}
                activeChapter={chapter_id}
                onChapterSelect={handleChapterSelect}
              />
            )}
          </div>
          <div>
            {bookDetails?.book?.name || ""}{" "}
            {activeChapter?.name
              ? `${activeChapter?.name ? activeChapter?.name : ""}`
              : ""}
          </div>
        </div>
      </div>

      <div className=" container clearfix v_audio-player-container">
        <div className="flex-column flex-md-row v_audio-player-wrapper">
          <div className="mt-1 col-md-4 va_img-section">
            <img
              className="w-100"
              src={bookDetails?.book?.book_image}
              alt="book img"
            />
          </div>

          <div className="col-12 col-md-8 va_player-section ps-md-5 ">
            <div className=" v_audio-player-text">
              <span className="fs-3 ">{bookDetails?.book.name}</span>
              <div>{/* by <span>{bookData.author.name}</span> */}</div>
              <div className="fs-7">
                {activeChapter ? activeChapter?.name : ""}
              </div>
            </div>
            <div>
              <AudioPlayer
                // src={bookData.book_audio}
                // middleText={activeChapter?.name}
                src={audio_url}
                // src={URL.createObjectURL(audio_url)}
                // src={`${baseUrl}/get-audio?url=${audio_url}`}
                showSkipControls={false}
                showLoopControl={false}
                showFilledVolume
                showSpeedControl
                showSleepControl
                onClickNext={() => {
                  // let nextChapter = Math.min(
                  //   activeChapter + 1,
                  //   sampleChapterData.length - 1
                  // );
                  // setActiveChapter(nextChapter);
                }}
                onClickPrevious={() => {
                  // let nextChapter = Math.max(activeChapter - 1, 0);
                  // setActiveChapter(nextChapter);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppViewAudioBook;
