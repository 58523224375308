import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { API_URL } from "../../pages/constant";
//Images
import authorAvatar from "./../../assets/images/author_avatar.png";

// import Swiper core and required modules
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import "../../assets/css/carosoul/AuthorSize.css";

//SwiperCore.use([EffectCoverflow,Pagination]);

export default function ClientsSlider() {
  const [authors, setAuthors] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/authors`)
      .then((response) => response.json())
      .then((data) => {
        setAuthors(data?.data?.authors);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className="section-head book-align">
        <h4 className="title mb-0">Authors</h4>
      </div>
      <Swiper
        className="swiper-container  client-swiper"
        speed={1500}
        parallax={true}
        slidesPerView={8}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 2000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1600: {
            slidesPerView: 8,
          },
          1400: {
            slidesPerView: 8,
          },
          1200: {
            slidesPerView: 7,
          },
          991: {
            slidesPerView: 6,
          },
          591: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 3,
          },
        }}
      >
        {authors?.map((author, i) => (
          <SwiperSlide key={i} className="swiper-slide">
            <div className="d-flex flex-column justify-content-center">
              <Link to={`/author-books/${author?.id}`}>
                {author?.profile_photo === "#" ? (
                  <img
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                    src={authorAvatar}
                    alt="authorAvatar"
                  />
                ) : author?.profile_photo ? (
                  <img
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                    src={author?.profile_photo}
                    alt="author_img"
                  />
                ) : (
                  <img
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                    src={authorAvatar}
                    alt="authorAvatar"
                  />
                )}
                <p className="text mb-0 mt-2">{author.name}</p>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
