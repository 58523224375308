import React from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const PaymentSuccess = () => {
    const location = useLocation();
    if (location.pathname === "/success") {
        Swal.fire("", `Payment successfull !`, "success");
            localStorage.removeItem("_cart");
            localStorage.removeItem("_price");
            localStorage.removeItem("pay_type");
            const redirectTimer = setInterval(() => {
                window.location.href = "/my-dashboard";
            }, 1000);
            return () => clearInterval(redirectTimer);        
    }
    return (
        <>
            <h1 className='text-center py-5'>Redirecting to My Profile...</h1>
        </>
    );
};

export default PaymentSuccess;