import React from "react";

function FullscreenIcon(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.83333 2H3.16667C3.01343 1.99991 2.86169 2.03003 2.7201 2.08863C2.57851 2.14723 2.44987 2.23316 2.34152 2.34152C2.23316 2.44987 2.14723 2.57851 2.08863 2.7201C2.03003 2.86169 1.99991 3.01343 2 3.16667V7.83333C2 8.14275 2.12292 8.4395 2.34171 8.65829C2.5605 8.87708 2.85725 9 3.16667 9C3.47609 9 3.77283 8.87708 3.99162 8.65829C4.21042 8.4395 4.33333 8.14275 4.33333 7.83333V4.33333H7.83333C8.14275 4.33333 8.4395 4.21042 8.65829 3.99162C8.87708 3.77283 9 3.47609 9 3.16667C9 2.85725 8.87708 2.5605 8.65829 2.34171C8.4395 2.12292 8.14275 2 7.83333 2Z"
        // fill="#464646"
      />
      <path
        d="M20.6667 2H15.3333C14.9797 2 14.6406 2.12292 14.3905 2.34171C14.1405 2.5605 14 2.85725 14 3.16667C14 3.47609 14.1405 3.77283 14.3905 3.99162C14.6406 4.21042 14.9797 4.33333 15.3333 4.33333H19.3333V7.83333C19.3333 8.14275 19.4738 8.4395 19.7239 8.65829C19.9739 8.87708 20.313 9 20.6667 9C21.0203 9 21.3594 8.87708 21.6095 8.65829C21.8595 8.4395 22 8.14275 22 7.83333V3.16667C22.0001 3.01343 21.9657 2.86169 21.8987 2.7201C21.8317 2.57851 21.7335 2.44987 21.6097 2.34152C21.4859 2.23316 21.3388 2.14723 21.177 2.08863C21.0152 2.03003 20.8418 1.99991 20.6667 2Z"
        // fill="#464646"
      />
      <path
        d="M7.83333 19.3333H4.33333V15.3333C4.33333 14.9797 4.21042 14.6406 3.99162 14.3905C3.77283 14.1405 3.47609 14 3.16667 14C2.85725 14 2.5605 14.1405 2.34171 14.3905C2.12292 14.6406 2 14.9797 2 15.3333V20.6667C1.99991 20.8418 2.03003 21.0152 2.08863 21.177C2.14723 21.3388 2.23316 21.4859 2.34152 21.6097C2.44987 21.7335 2.57851 21.8317 2.7201 21.8987C2.86169 21.9657 3.01343 22.0001 3.16667 22H7.83333C8.14275 22 8.4395 21.8595 8.65829 21.6095C8.87708 21.3594 9 21.0203 9 20.6667C9 20.313 8.87708 19.9739 8.65829 19.7239C8.4395 19.4738 8.14275 19.3333 7.83333 19.3333Z"
        // fill="#464646"
      />
      <path
        d="M20.6667 14C20.4915 13.9999 20.3181 14.0343 20.1563 14.1013C19.9945 14.1683 19.8475 14.2665 19.7236 14.3903C19.5998 14.5141 19.5016 14.6612 19.4346 14.823C19.3677 14.9848 19.3332 15.1582 19.3333 15.3333V19.3333H15.3333C14.9797 19.3333 14.6406 19.4738 14.3905 19.7239C14.1405 19.9739 14 20.313 14 20.6667C14 21.0203 14.1405 21.3594 14.3905 21.6095C14.6406 21.8595 14.9797 22 15.3333 22H20.6667C20.8418 22.0001 21.0152 21.9657 21.177 21.8987C21.3388 21.8317 21.4859 21.7335 21.6097 21.6097C21.7335 21.4859 21.8317 21.3388 21.8987 21.177C21.9657 21.0152 22.0001 20.8418 22 20.6667V15.3333C22.0001 15.1582 21.9657 14.9848 21.8987 14.823C21.8317 14.6612 21.7335 14.5141 21.6097 14.3903C21.4859 14.2665 21.3388 14.1683 21.177 14.1013C21.0152 14.0343 20.8418 13.9999 20.6667 14Z"
        // fill="#464646"
      />
    </svg>
  );
}

export default FullscreenIcon;
